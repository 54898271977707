/*
 * @Author: duchongchong1
 * @Date: 2022-09-19 10:19:43
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-02-28 09:45:10
 * @FilePath: /isp_International/src/components/NewFooter/index.tsx
 * @Description:
 */
import React from 'react';
import styled from './index.module.scss';
import getImg from '@/util/getImg';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/images/logo/京东工采国际@2x.png';
import { isIE } from '@/util';
import { getJdiOSFile } from '@/util/index'

interface IAppProps {
  type?: any;
}

const NewFooter: React.FC<IAppProps> = (props: IAppProps) => {
  const { i18n, t } = useTranslation();
  return (
    <div className={`${styled.main}`}>
      <div className={`${styled.main_box}`}>
        <img
          onClick={() => {
            if (isIE()) {
              window.location.href = '/';
            } else {
              window.open('/', '_self');
            }
          }}
          style={{ cursor: 'pointer' }}
          src={ getJdiOSFile('d1/writeLogo.png') }
          alt="JD logo"
        />
        <div>
          {/* 京东商城：https://www.jd.com/
京东五金城：https://mro.jd.com/
工品优选：https://www.vipmro.com/ */}
          <p className={`${styled.copyrightPerson}`}>
            <a href="https://www.jd.com/" target="_blank" rel="noreferrer">
              {TLT(`京东商城`)}
            </a>
          <i></i>
            <a href="https://mro.jd.com/" target="_blank" rel="noreferrer">
              {TLT(`京东五金城`)}
            </a>
            <i></i>
            <a href="https://www.vipmro.com/" target="_blank" rel="noreferrer">
              {TLT(`工品优选`)}
            </a>
          </p>
          <p className={`${styled.copyright}`}>{TLT(`Copyright © 2004-2024 JD.COM. 版权所有`)}</p>
        </div>
      </div>
    </div>
  );
};
export default NewFooter;
