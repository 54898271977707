import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Modal, Col, Affix } from 'antd';

import UserCenterMenu from '@/components/UserCenterMenu';

import styles from './index.module.scss';
import ChangePasswordModal from './components/ChangePasswordModal';
import ChangeEmailModal from './components/ChangeEmailModal';
import { useSelector } from 'react-redux';
import Logo from '@/assets/images/icon.png';
import moment from 'moment';
import CommonBasicHeader from '@/components/CommonBasicHeader';
import { getAccountInfo } from '@/api/newApi/personalCenter/accountSettings';
import { desensitization } from '@/util/tools';
import { useTranslation } from 'react-i18next';

const AccountSettings: React.FC = () => {
  const { t } = useTranslation();
  // 当前用户
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  // 修改密码弹窗flag
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState<boolean>(false);
  // 修改密码邮箱flag
  const [changeEmailModalVisible, setChangeEmailModalVisible] = useState<boolean>(false);
  // 图像地址，
  // TODO 暂时不删预留上传
  const [imageUrl, setImageUrl] = useState<string>(currentUser.userImage || Logo);
  // 账户详情
  const [accountInfo, setAccountInfo] = useState<{
    phone?: string;
    email?: string;
    pin?: string;
  }>({});
  /**
   * 获取账户详情
   */
  const getData = () => {
    getAccountInfo().then((res: any) => {
      setAccountInfo(res.value ?? {});
    });
  };
  // 初始化获取信息
  useEffect(() => {
    getData();
  }, []);
  // 设置用户登录信息
  useEffect(() => {
    if (currentUser?.userProfile || currentUser?.userImage) {
      setImageUrl(currentUser?.userProfile || currentUser?.userImage);
    }
  }, [currentUser]);

  // 当前时间
  const hour = moment().hour();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="ispContainer">
        <Affix offsetTop={0}>
          <UserCenterMenu />
        </Affix>
        <div className={styles.content}>
          <CommonBasicHeader title={TLT(`账户管理`)}></CommonBasicHeader>
          <Card className={styles.main} style={{ padding: '67px 0 60px' }}>
            <Row align="middle" justify="start" style={{ flexDirection: 'column' }}>
              <div className={styles.headPortrait}>
                <div className={styles.header}>
                  <img src={imageUrl} style={{ width: '72px', height: '72px', objectFit: 'cover' }} />
                </div>
                <div className={styles.name}>
                  <span>
                    {hour <= 10 && TLT(`早上好`)}
                    {hour > 10 && hour <= 14 && TLT(`中午好`)}
                    {hour > 14 && hour <= 18 && TLT(`下午好`)}
                    {hour > 18 && TLT(`晚上好`)}，
                  </span>
                  <div>{accountInfo?.pin}</div>
                </div>
              </div>
              <Col className={styles.infoContent}>
                <Row className={styles.info} align="middle">
                  <span className={styles.listTitle}>{TLT(`账户类型`)}</span>
                  <span className={styles.listValue}>
                    {currentUser.userType === 4 && TLT(`管理员`)}
                    {currentUser.userType === 5 && TLT(`采购账号`)}
                    {currentUser.userType === 6 && TLT(`审批账号`)}
                    {/* {USER_TYPE[currentUser.userType]} */}
                  </span>
                </Row>
                <Row className={styles.info} align="middle" justify="space-between">
                  <Row>
                    <span className={styles.listTitle}>{TLT(`登录密码`)}</span>
                    <span className={styles.listValue}>************</span>
                  </Row>
                  <Row>
                    <Button
                      type="link"
                      onClick={() => {
                        setChangePasswordModalVisible(true);
                        log('jdisp', 'Account_UpdatePassword');
                      }}
                    >
                      {TLT(`修改`)}
                    </Button>
                  </Row>
                </Row>
                {accountInfo.phone && (
                  <Row className={styles.info} align="middle" justify="space-between">
                    <Row>
                      <span className={styles.listTitle}>{TLT(`绑定手机`)}</span>
                      <span className={styles.listValue}>{desensitization('phone', accountInfo.phone || '')}</span>
                    </Row>
                    {/* <Row>
                    <Button type="link" onClick={() => setChangeMobileModalVisible(true)}>
                      修改
                    </Button>
                  </Row> */}
                  </Row>
                )}
                <Row className={styles.info} align="middle" justify="space-between">
                  <Row>
                    <span className={styles.listTitle}>{TLT(`绑定邮箱`)}</span>
                    <span className={styles.listValue}>{desensitization('email', accountInfo.email || '')}</span>
                  </Row>
                  <Row>
                    <Button
                      type="link"
                      onClick={() => {
                        setChangeEmailModalVisible(true);
                        log('jdisp', 'Account_UpdateEmail');
                      }}
                    >
                      {TLT(`修改`)}
                    </Button>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <ChangePasswordModal visible={changePasswordModalVisible} onCancel={() => setChangePasswordModalVisible(false)} />
      {/* <ChangeMobileModal
        visible={changeMobileModalVisible}
        onCancel={() => setChangeMobileModalVisible(false)}
        onOK={async () => {
          setChangeMobileModalVisible(false);
          const userData: any = (await getUser()) as any;
          if (userData.code == 0) {
            dispatch(dispatchUser(userData.value));
          }
        }}
      /> */}
      <ChangeEmailModal
        visible={changeEmailModalVisible}
        onCancel={() => {
          setChangeEmailModalVisible(false);
        }}
        onOK={(email: string) => {
          setChangeEmailModalVisible(false);
          const [prefix, suffix] = TLT(`系统邮件已发送至您的邮箱：%{email}%，发送了一封验证邮件，请您登录邮箱，点击邮箱链接完成邮箱验证，邮箱即绑定成功。`).split('%{email}%');
          Modal.success({
            title: TLT(`邮件发送成功`),
            closable: true,
            okText: TLT(`知道了`),
            content: (
              <div>
                {prefix}
                <span className={styles.blue}>{email}</span>
                {suffix}
              </div>
            ),
          });
        }}
      />
    </div>
  );
};
export default AccountSettings;
