/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-16 10:58:05
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-09-02 14:23:28
 * @FilePath: /isp_International/src/pages/SearchList/components/GoodsList/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState } from 'react';
import cookies from 'react-cookies';
import Carousel from '../Carousel';
import { replaceString, checkSortType, getCookie } from '@/util';
import { formatMoney } from '@/util/index';
import { isGuest } from '@/util/commonUtils';
import PaginationJD from '@/components/PaginationJD';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Page from '@/components/SearchList/Page';
import { useTranslation } from 'react-i18next';
import { dispatchAnnounceData } from '@/redux/action';
import Empty from '@/components/common/Empty';
import { Space, Spin } from 'antd';
import Replenishment from '@/components/Replenishment';
const PageC = memo(Page);
export interface IAppProps {
  pageInfo: any;
  changeGoodData: any;
  chooseGood: any;
  chooseGoodItem: any;
  chooseGoodIndex: any;
  addCarBe: Function;
  isWideScreen: boolean;
  updataData: any;
  searchedWord: string;
}
const Index: React.FC<IAppProps> = (props: any) => {
  const { changeGoodData, chooseGoodItem, chooseGoodIndex, chooseGood, addCarBe, searchedWord, updataData } = props;
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });

  const {
    pageSize,
    selectParams,
    params,
    pageInfo,
    extAttrList,
    brandList,
    priceRangeList,
    isShowJDPrice,
  } = red_goods_data;
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土 
  const [isShow, setIsShow] = useState(false); //展示
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedShopInfo, setSelectedShopInfo] = useState<any>(null); //选中的商品
  const userInfo: any = useSelector((state: any) => {
    return state?.User?.data[0];
  });
  const goTop = () => {
    const anchorElement = document.getElementById('__position');
    if (anchorElement) {
      anchorElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.content} style={{ width: '992px' }}>
      <PageC updataData={updataData} from={'SearchListCable'} searchWhite={false}></PageC>
      <div className={`${styles.larger_img}`}>
        <div className={`${styles.gl_warp}`} id="gl_warp">
          {changeGoodData?.length > 0 &&
            changeGoodData?.map((item: any, index: any) => {
              return (
                <div className={`${styles.gl_item}`} key={item.sku || index}>
                  <Carousel mainData={item} chooseGood={chooseGood} userInfo={userInfo} />
                  {/* 跨进本土展示价格样式不一致  需跟产品讨论考虑是否保持一致 */}
                  {item.showAttrs.price != -1 ? (
                    
                    isLocalPro ? <div className={`${styles.ent_price}`}>
                      {Number(item?.saleState?.saleState) === 1 ? (
                        <>
                         <div className={styles.contain_price}>
                          <span className={styles.label}>{TLT(`含税价`)}</span>
                            <span>
                              <em>{item?.showCurrency?.symbol}</em>
                              <span>{formatMoney(item?.showCurrency?.salePrice, false)}</span>
                            </span>
                          </div>
                         <div className={styles.contain_price}>
                            <span className={styles.label}>{TLT(`未税价`)}</span>
                            <span>
                              <em>{item?.showCurrency?.symbol}</em>
                              <span>{formatMoney(item?.showCurrency?.price, false)}</span>
                            </span>
                          </div>
                        </>
                      ) : (
                        <em>{TLT(`无法查看采购价`)}</em>
                      )}
                    </div> : <div className={`${styles.ent_price}`}>
                      {Number(item?.saleState?.saleState) === 1 ? (
                        <>
                          <em>{item?.showCurrency?.symbol}</em>
                          {item?.showAttrs?.price}
                          {item?.showCurrency?.valueAddedTaxRate && <div>
                            <span style={{
                              fontSize: '12px',
                            }}>{TLT(`未税价`)}</span>
                            <em style={{
                              marginLeft: '5px',
                            }}>{item?.showCurrency?.symbol}</em>
                            <span>{item?.showCurrency?.price}</span>
                          </div>}
                        </>
                      ) : (
                        <em>{TLT(`无法查看采购价`)}</em>
                      )}
                    </div>
                  ) : (
                    <div className={`${styles.jd_price}`}>暂无报价</div>
                  )}

                  <div className={`${styles.title_1}`}>
                    <a
                      title={item.showAttrs?.wareName}
                      href={`/sku/${item.showAttrs.sku}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceString(`${item?.sourceCountryCode == 'CN' ? TLT(`【跨境】`) : ''}${item.showAttrs.wareName}`, searchedWord),
                        }}
                      ></div>
                    </a>
                  </div>
                  <div className={`${styles.order_Num} ${styles.text_space}`} title={item.showAttrs.sku || ''}>
                    {TLT(`商品编号`)}: {item?.showAttrs?.sku || ''}
                  </div>
                  {/* 非游客 本土或者跨境且有库存才展示  */}
                  {!isGuest() && ((!isLocalPro && item?.remainNum !== 0) || isLocalPro) && <div className={`${styles.operation}`}>
                    <div
                      className={`${styles.add_shop}`}
                      onClick={() => {
                        if (chooseGoodItem.length > 0 && chooseGoodItem[chooseGoodIndex].sku === item.showAttrs.sku) {
                          //@ts-ignore
                          addCarBe(
                            chooseGoodItem[chooseGoodIndex]?.saleState?.saleState,
                            chooseGoodItem[chooseGoodIndex],
                          );
                        } else {
                          addCarBe(item?.saleState?.saleState, item);
                        }
                        log('jdisp', 'Search_AddtoCart');
                      }}
                    >
                      <i></i>
                      {TLT(`加入采购清单`)}
                    </div>
                  </div>
                  }

                  {!isLocalPro && item?.remainNum === 0 && <div className={`${styles.operation}`}>
                    <div className={`${styles.replenishment}`} onClick={() => {
                      setIsShow(true)
                      setSelectedShopInfo({
                        mkuId:item.sku,
                        img:item.img,
                        productName:item.wareName,
                      })
                    }}>
                      <i></i>
                      {TLT(`申请补货`)}
                    </div>
                  </div>}
                 {/* 货期任何时候都展示 优先于库存展示*/}
                  {
                    !item?.deliveryDate && item?.remainNum === 0 && (<div className={`${styles.stock}`}>{TLT(`无货`)}</div>)
                  }
                  {
                    !item?.deliveryDate && item?.remainNum > 0 && item?.remainNum <= 200 && (
                      <div className={`${styles.stock} ${styles.stock_surplus100}`}>
                        {TLT(`库存仅剩XX件`).replace("XX",item?.remainNum)}
                      </div>
                    )
                  }
                  {!isLocalPro && item?.deliveryDate && <div className={`${styles.deliveryDate}`}>
                      {item?.deliveryDate}
                  </div>}
                </div>
              );
            })}
          {changeGoodData.length <= 0 &&
            (extAttrList.length > 0 || brandList.length > 0 || priceRangeList.length > 0) && <Empty type="reset" />}
        </div>

        <div className={`${styles.pagination}`}>
          {changeGoodData?.length > 0 && pageInfo && pageInfo.pageIndex ? (
            <PaginationJD
              hideOnSinglePage
              current={pageInfo.pageIndex}
              total={pageInfo.maxResultCount}
              pageSize={pageSize}
              showSizeChanger={false}
              onChange={(_page: any) => {
                const paramsData = {
                  ...params,
                  pageNo: _page,
                };
                dispatch(dispatchAnnounceData(paramsData, true, selectParams));
                goTop();
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <Replenishment isVisible={isShow} setIsVisible={(isVisble)=>setIsShow(isVisble)} shopInfo={selectedShopInfo} />
    </div>
  );
};
export default Index;
