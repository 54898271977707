import React, { useState } from 'react';
import {
  // printOrder,
  deleteOrder,
  restoreOrder,
  // cancelOrder,
  payOrder,
  // confirmOrder,
  auditOrder,
  buyAgainOrder,
} from '@/api/orderList/api';
import {
  cancelOrder,
  confirmOrder,
  printOrder,
  batchConfirmOrder,
  buyAgainAddProducts,
  orderConfirm,
} from '@/api/newApi/order';
import { message, Modal, Button, Alert, Row, Col, Radio, Space, Input, Form } from 'antd';
// import { toMergePay } from '@/api/mergepay/api';
const { TextArea } = Input;

import moment from 'moment';
import Icon from '@/components/common/Icon';
import iconWarning from '@/assets/images/orderList/icon_warning.svg';
import { ReactComponent as IconInfo } from '@/assets/images/paymentRecognition/icon_info.svg';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { getApprovalAction } from '@/api/newApi/approvalList';

export enum OrderStatus {
  all = '全部订单',
  committed = '已提交',
  confirming = '确认中', //只用来筛选 传20
  shipping = '发货中',
  shipped = '已发货',
  complete = '已完成',
  cancel = '已取消',
  waitingConfirm = '待确认',
  waitingApprove = '待审核',
  deliverySubmitted = "已妥投",
  confirmReceipt = "已确认收货",
}

export function orderStatus(order: any): string {
  const { orderStatus } = order;
  const { orderState } = order;
  switch (orderStatus || orderState) {
    case 1:
      return OrderStatus.committed;
    case 2:
      return OrderStatus.waitingConfirm;
    case 3:
      return OrderStatus.waitingApprove;
    case 4:
      return OrderStatus.shipping;
    case 5:
      return OrderStatus.complete;
    case 80:
      return OrderStatus.shipped;
    case 90:
      return OrderStatus.deliverySubmitted;
    case 99:
      return OrderStatus.cancel;
    default:
      return '';
  }
}

export function canCancel(order: any): boolean {
  return (
    order.orderState === 1 &&
    ((order.isWhiteCancel && order.jdOrderState <= 10) || (!order.isWhiteCancel && order.jdOrderState <= 5))
  );
}
export enum AuditStatus {
  none = '未发起',
  approved = '通过',
  deny = '驳回',
  auditing = '审批中',
  cancel = '已取消',
}
export function approvalStatus(order: any): AuditStatus {
  const { approvalStatus } = order;
  switch (approvalStatus || 0) {
    case 1:
      return AuditStatus.auditing;
    case 2:
      return AuditStatus.approved;
    case 3:
      return AuditStatus.deny;
    case 4:
      return AuditStatus.cancel;
    default:
      return AuditStatus.none;
  }
}

// approved = '通过',  2
// deny = '驳回',  3
  // 中断   4
// auditing = '审批中', 2
// 待审批  ==   =<1


export function auditStatus(approvalStatusMap: any, order: any): AuditStatus {
  const { jdOrderId, confirmState } = order;
  switch (approvalStatusMap[jdOrderId]) {
    case 1:
      return confirmState === 1 ? AuditStatus.approved : AuditStatus.auditing;
    case 2:
    case 7:
      return AuditStatus.approved;
    case 3:
      return AuditStatus.deny;
    case 4:
      return confirmState === 1 ? AuditStatus.approved : AuditStatus.cancel;
    case 5:
    case 6:
    case 8:
      return AuditStatus.cancel;
    default:
      return AuditStatus.none;
  }
}

export function paymentType(order: any): string {
  switch (order.orderPayment ? order.orderPayment.paymentType : order.paymentType) {
    case 1:
      return '货到付款';
    case 4:
      return '在线余额';
    case 5:
      return '对公转账';
    case 7:
      return '网银钱包';
    case 12:
      return '账期/月结';
    case 14:
      return '快捷支付';
    case 101:
      return '京东金采';
    case 102:
      return '京东金采';
    default:
      return '';
  }
}

export function print(orderIds: string, type: string, t: any) {
  printOrder({ orderIds, type }).then(() => {
    message.success(TLT(`订单打印成功！请前往下载中心查看`));
  });
}

//没用
export function removeOrder(orderIds: string, complete?: () => void) {
  Modal.confirm({
    title: '确认删除订单',
    content: '请确认是否删除所选订单，删除后的订单将进入订单回收站。',
    okText: '确认',
    cancelText: '取消',
    onOk: () => {
      deleteOrder({ orderIds }).then((res: any) => {
        if (res.success) {
          message.success('删除成功！');
          complete && complete();
        } else {
          message.error(res.msg);
        }
      });
    },
  });
}

export function restore(orderIds: string, complete?: () => void) {
  restoreOrder({ orderIds }).then(() => {
    complete && complete();
  });
}

export function orderTime(order: any) {
  return order.createOrderTime || order.creatOrderTime || order.creatOrderDate || order.createDate || order.orderTime;
}

export const CancelModal: React.FC<{ order: any; visible: boolean; onCancel?: () => void; onOK?: () => void }> = ({
  order,
  visible,
  onCancel,
  onOK,
}) => {
  const [reason, setReason] = useState<number>(0);
  const { t } = useTranslation();
  return (
    <Modal width={464} title=" " open={visible} footer={null} onCancel={onCancel}>
      <Row>
        <Col span="2">
          <ExclamationCircleFilled style={{ fontSize: '24px', color: 'rgba(237, 85, 2, 1)' }} />
        </Col>
        <Col span="20" style={{ fontSize: '16px', fontWeight: '500', color: 'rgba(0,3,20,0.85)' }}>
          {TLT(`取消订单？`)}
        </Col>
      </Row>
      <Row style={{ marginTop: '6px' }}>
        <Col span="2"></Col>
        <Col span="20" style={{ fontSize: '14px', fontWeight: '400', color: 'rgba(0,3,20,0.45)' }}>
          {TLT(`取消后订单将无法恢复。`)}
        </Col>
      </Row>

      <Row justify="end">
        <Button
          type="primary"
          key="cancelOrder1"
          onClick={() => {
            cancelOrder({ orderNo: order.orderNo, orderStatus: 99 }).then(() => {
              message.success(TLT(`订单取消申请已提交！`));
              onOK && onOK();
            });
          }}
          style={{ fontSize: '14px', width: '88px', height: '32px', marginRight: '16px', marginTop: '30px' }}
        >
          {TLT(`确认`)}
        </Button>
        <Button
          type="primary"
          ghost
          key="cancelOrder2"
          onClick={() => {
            onCancel && onCancel();
          }}
          style={{ fontSize: '14px', width: '88px', height: '32px', marginTop: '30px' }}
        >
          {TLT(`再想想`)}
        </Button>
      </Row>
    </Modal>
  );
};

export function buyAgain(
  skus: Array<{ sku: string; num: number; price: number }>,
  history: any,
  jump = true,
  complete?: () => void,
) {
  buyAgainAddProducts(skus).then((res) => {
    const {success, msg} = res
    if(!success){
      message.error(msg);
      return;
    }
    complete && complete();
    if (jump) {
      window.open('/cart/carts', '_blank');
      // history.push('/cart/carts');
    }
  });
}
//没用
export function buyAgain2(
  skus: Array<{
    mkuId: string;
    skuRemark: string;
    num: number;
    skuSettleServiceParams: Array<{ serviceSkuId: string; source: string }>;
  }>,
  history: any,
) {
  buyAgainOrder(skus).then((res: any) => {
    if (res && res.success) {
      if (res.value || '') {
        message.error(res.value);
      }
      history.push('/order/goOrderInfo?tradeModel=3');
    }
  });
}

function postcall(url: string, params: any, target?: string) {
  const tempform = document.createElement('form');
  tempform.action = url;
  tempform.method = 'post';
  tempform.style.display = 'none';
  if (target) {
    tempform.target = target;
  }

  for (const x in params) {
    const opt = document.createElement('input');
    opt.name = x;
    opt.value = params[x];
    tempform.appendChild(opt);
  }

  const opt = document.createElement('input');
  opt.type = 'submit';
  tempform.appendChild(opt);
  document.body.appendChild(tempform);
  tempform.submit();
  document.body.removeChild(tempform);
}

// export function mergePay(mergeCode: string) {
//   toMergePay({ mergeCode }).then((res: any) => {
//     if (res.success) {
//       postcall(res.value.form.action, res.value.form.submitParameters);
//     } else {
//       message.error(res.msg);
//     }
//   });
// }

export function pay(orderId: string) {
  payOrder({ orderId }).then((res: any) => {
    if (res.success) {
      postcall(res.value.payUrl, res.value.payOrderInfo);
    } else {
      message.error(res.msg);
    }
  });
}
export function handleConfirmOrder(orderId: string, t: any, complete?: () => void) {
  Modal.confirm({
    title: TLT(`确认订单`),
    content: TLT(`点击确认后，且订单付款后，则订单会下传生产，准备供货。`),
    cancelText: TLT(`确认`),
    okText: TLT(`再想想`),
    // ui要求蓝色是积极按钮 dialog里cancel是蓝色 所以反着写
    onCancel: () => {
      orderConfirm({ orderNo: orderId, orderStatus: 3 }).then((res: any) => {
        if (res.success) {
          message.success(TLT(`确认订单成功！`));
          complete && complete();
        } else {
          message.error(res.msg);
        }
      });
    },
  });
}
export function confirm(orderId: string, complete?: () => void, t: any) {
  Modal.confirm({
    title: TLT(`确认收货`),
    content: TLT(`您确定对所选订单进行收货操作吗？收货确认后，订单会完成`),
    cancelText: TLT(`确认`),
    okText: TLT(`再想想`),
    onCancel: () => {
      confirmOrder({ orderNo: orderId, orderStatus: 5 }).then((res: any) => {
        if (res.success) {
          message.success(TLT(`确认收货成功`));
          complete && complete();
        } else {
          message.error(res.msg);
        }
      });
    },
  });
}

export function batchConfirm(orderIdsData: Array, complete?: () => void, t: any) {
  if (orderIdsData.length > 0) {
    orderIdsData = orderIdsData.map(item => {
      const i = {
        orderNo: item,
        orderStatus: 5,
      };
      return i;
    });
  } else {
    return;
  }
  Modal.confirm({
    title: TLT(`确认收货`),
    content: TLT(`您确定对所选订单进行收货操作吗？收货确认后，订单会完成`),
    cancelText: TLT(`确认`),
    okText: TLT(`再想想`),
    onCancel: () => {
      batchConfirmOrder(orderIdsData).then((res: any) => {
        if (res.success) {
          message.success(TLT(`确认收货成功`));
          complete && complete();
        } else {
          message.error(res.msg);
        }
      });
    },
  });
}

export const ApproveModal: React.FC<{
  orders: Array<{ jdOrderId: string; processId: string; pin: string }>;
  visible: boolean;
  onCancel?: () => void;
  onOK?: () => void;
}> = ({ orders, visible, onCancel, onOK }) => {
  const { t } = useTranslation();
  return (
    <Modal
      width={480}
      title=""
      open={visible}
      footer={
        <Row justify="end" style={{ padding: '10px 0' }}>
          <Button
            key="cancel"
            onClick={() => {
              onCancel && onCancel();
            }}
            style={{ fontSize: '14px', width: '124px', height: '32px', marginRight: '10px' }}
          >
            {TLT(`再想想`)}
          </Button>
          <Button
            type="primary"
            key="ok"
            onClick={() => {
              getApprovalAction({ operate: 1, orders: JSON.stringify(orders) }).then((res: any) => {
                if (res.success) {
                  message.info(TLT(`审批通过`));
                  onOK && onOK();
                } else {
                  message.error(res.msg);
                  onCancel && onCancel();
                }
              });
            }}
            style={{ fontSize: '14px', width: '124px', height: '32px' }}
          >
            {TLT(`通过`)}
          </Button>
        </Row>
      }
      onCancel={onCancel}
    >
      <Row style={{ alignItems: 'center' }}>
        <IconInfo style={{ width: '26px', height: '26px', color: '#faa21e', fill: 'currentcolor' }} />
        <div style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}>
          {TLT(`订单审批`)}
        </div>
      </Row>
      <div style={{ marginLeft: '34px' }}>
        <div style={{ color: '#666', marginTop: '20px' }}>{TLT(`确认审批通过`)}</div>
        <div style={{ fontSize: '12px', color: '#999', marginTop: '20px' }}>
          {TLT(`温馨提示：`)}
          <br />
          {TLT(`1. 审批通过的订单不可取消。`)}
          <br />
          {TLT(`2. 完成支付的订单在审批通过后，会开始安排发货:`)}
          <br />
          {TLT(`3. 审批结果提醒会以邮件的形式发送给采购人，需要采购人提前绑定邮箱。`)}
        </div>
      </div>
    </Modal>
  );
};
export const RejectModal: React.FC<{
  orders: Array<{ jdOrderId: string; processId: string; pin: string }>;
  visible: boolean;
  onCancel?: () => void;
  onOK?: () => void;
}> = ({ orders, visible, onCancel, onOK }) => {
  const [reason, setReason] = useState<string>('');
  const [form] = Form.useForm();

  const onFinish = () => {
    getApprovalAction({ operate: 0, orders: JSON.stringify(orders), reasone: reason }).then((res: any) => {
      if (res.success) {
        message.info(TLT(`已驳回`));
        onOK && onOK();
      } else {
        message.error(res.msg);
      }
    });
  };
  const { t } = useTranslation();

  return (
    <Modal
      destroyOnClose={true}
      width={480}
      title=""
      open={visible}
      footer={
        <Row justify="end" style={{ padding: '10px 0' }}>
          <Button
            key="cancel"
            onClick={() => {
              onCancel && onCancel();
            }}
            style={{ fontSize: '14px', width: '124px', height: '32px', marginRight: '10px' }}
          >
            {TLT(`再想想`)}
          </Button>
          <Button
            type="primary"
            key="ok"
            style={{ fontSize: '14px', width: '124px', height: '32px' }}
            onClick={() => form.submit()}
          >
            {TLT(`驳回`)}
          </Button>
        </Row>
      }
      onCancel={onCancel}
    >
      <Row style={{ alignItems: 'center' }}>
        <IconInfo style={{ width: '26px', height: '26px', color: '#faa21e', fill: 'currentcolor' }} />
        <div style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '10px' }}>
          {TLT(`订单审批`)}
        </div>
      </Row>
      <div style={{ marginLeft: '34px' }}>
        <div style={{ color: '#666', marginTop: '20px' }}>
          {TLT(`确认驳回选中订单？请填写驳回理由`)}
          
        </div>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label=""
            name="reason"
            rules={[
              { required: true, message: `${TLT(`未填写驳回原因`)}` },
              { type: 'string', max: 50, message: `${TLT(`驳回原因最多50个字`)}` },
            ]}
          >
            <TextArea
              placeholder={TLT(`请输入驳回原因，原因必填`)}
              rows={4}
              style={{ marginTop: '20px' }}
              onChange={(e: any) => setReason(e.target.value)}
            />
          </Form.Item>
          <div style={{ fontSize: '12px', color: '#999', marginTop: '20px' }}>
            {TLT(`温馨提示：`)}
            <br />
            {TLT(`1. 驳回后，订单将被取消，此前占用的库存和价格将失效。`)}
            <br />
            {TLT(`2. 审批结果提醒会以邮件的形式发送给采购人，需要采购人提前绑定邮箱。`)}
          </div>
        </Form>
      </div>
    </Modal>
  );
};
