import React, { useEffect, useState } from 'react';
import { Table, message, Tooltip, Checkbox } from 'antd';
import LazyLoad from 'react-lazyload';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QuestionCircleOutlined } from '@ant-design/icons';
import cookies from 'react-cookies';
import { useRecoilState, useRecoilValue } from '../../../hooks';
import Good from '../../../types/good';
import { getImageDomain } from '../../../utils';
import { toPercent } from '@/util/tools'

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatMoney } from '@/util';

const Index = (props: any) => {
  const [shipment, setShipment] = useRecoilState('shipment');
  const totalKind = shipment?.productList?.length; // 总件数
  const { t } = useTranslation();
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土
  const userInfo: any = useSelector((state: any) => {
    return state?.User?.data[0];
  });

  let totalCount = 0; // 商品总数
  shipment?.productList?.forEach(item => {
    totalCount += item.num || 0;
  });

  const [skuList, setSkuList] = useState([]);
  const [loading, setLoading] = useState(false);

    // 获取用户操作权限
    const userLimit: any = useSelector((state: any) => {
      return state?.UserLimit;
    });

  const getGifts = (good: any) => {
    if (good?.gifts) {
      return Object.values(good?.gifts)[0];
    } else {
      return 0;
    }
  };

  useEffect(() => {
    // 使用loading状态强制组件在数据更新时渲染
    setLoading(false);
    const productList: any = shipment?.productList;
    productList && setSkuList(productList);
    setLoading(true);
  }, [shipment]);

  const columns = [
    {
      title: () => {
        return <div style={{ paddingLeft: '16px' }}>{TLT(`商品信息`)}</div>;
      },
      width: '35%',
      render: (_text: unknown, good: Good) => (
        <div className={styles.good} style={{ paddingLeft: '16px' }}>
          <a
            href={`/sku/${good.sku}`}
            className={styles.img}
            target="_blank"
            rel="noopener noreferrer"
            title={good.skuName}
          >
            <LazyLoad height={75}>
              <img src={good.skuImg} alt={good.skuName} />
            </LazyLoad>
          </a>
          <div className={styles.detail}>
            <div>
              <a href={`/sku/${good.sku}`} target="_blank" rel="noopener noreferrer" title={good.skuName}>
                {`${good?.sourceCountryCode == 'CN' ? TLT(`【跨境】`) : ''}${good.skuName}`}
              </a>
            </div>
            <CopyToClipboard
              text={good.sku}
              onCopy={() => {
                message.info(TLT(`已复制`));
              }}
            >
              <div className={styles.detail_code}>
                <span>{TLT(`商品编码：`)}</span>
                <span> {good.sku}</span>
              </div>
            </CopyToClipboard>

            {userLimit?.limitData?.enableMaterial == 1 && <CopyToClipboard
              text={good?.mkuMaterial?.materialId}
              onCopy={() => {
                message.info(TLT(`已复制`));
              }}
            >
              <div className={styles.detail_code}>
                <span>{TLT(`物料编码`)}：</span>
                <span> {good?.mkuMaterial?.materialId || '-'}</span>
              </div>
            </CopyToClipboard>}

            {good?.gifts && (
              <div className={styles.detail_gifts}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{ marginRight: '6px', color: '#E00224', padding: '2px', fontSize: '14px' }}>{TLT(`附件`)}</p>
                  <img
                    src={`https://img30.360buyimg.com/popshop/${getGifts(good)?.img_dfs_url}`}
                    style={{ height: '22px', width: '22px', padding: '0px', marginBottom: '-6px', marginRight: '8px' }}
                    alt={TLT(`附件`)}
                  />
                  <p style={{ marginRight: '8px', color: '#E00224', padding: '2px', fontSize: '14px' }}>
                    x{getGifts(good)?.num}
                  </p>
                </div>
                {/*<div style={{ display: 'flex', justifyContent: 'center' }}>*/}
                {/*  <p style={{ marginRight: '6px', color: '#E00224', padding: '2px', fontSize: '14px' }}>{TLT(`赠品`)}</p>*/}
                {/*  <img*/}
                {/*    src={`https://img30.360buyimg.com/popshop/${getGifts(good)?.img_dfs_url}`}*/}
                {/*    style={{ height: '22px', width: '22px', padding: '0px', marginBottom: '-6px', marginRight: '8px' }}*/}
                {/*    alt={TLT(`赠品`)}*/}
                {/*  />*/}
                {/*  <p style={{ marginRight: '5px', color: '#E00224', padding: '2px', fontSize: '14px' }}>*/}
                {/*    x{getGifts(good)?.num}*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
            )}
          </div>
        </div>
      ),
    },
    // {
    //   title: () => {
    //     return <div style={{ textAlign: 'center' }}>{TLT(`型号`)}</div>;
    //   },
    //   width: 160,
    //   render: (_text: unknown, good: Good) => (
    //     <div className={styles.num}>
    //       {good?.model ? (
    //         <CopyToClipboard
    //           text={good?.model}
    //           onCopy={() => {
    //             message.info(TLT(`已复制`));
    //           }}
    //         >
    //           <span>{good?.model}</span>
    //         </CopyToClipboard>
    //       ) : null}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => {
    //     return <div style={{ textAlign: 'center' }}>{TLT(`单位`)}</div>;
    //   },
    //   width: 30,
    //   render: (_text: unknown, good: Good) => (
    //     <div className={styles.price}>
    //       <div>{good?.unit}</div>
    //     </div>
    //   ),
    // },
    {
      title: () => {
        return <div style={{ width: '100%', textAlign: 'center', paddingLeft: '4px' }}>{TLT(`价格`)}</div>;
      },
      width: 100,
      render: (_text: unknown, good: Good) => (
       isLocalPro ? 
       <div className={styles.price}>
          <div style={{
            marginTop: '3px',
            fontSize: '12px',
            color: '#999999',
          }}>{TLT(`含税价`)}{good?.showCurrency?.symbol + formatMoney(good?.showCurrency?.salePrice, false)}</div>
          <div style={{
            marginTop: '3px',
            fontSize: '12px',
            color: '#999999',
          }}>{TLT(`未税价`)} {good?.showCurrency?.symbol + formatMoney(good?.showCurrency?.price, false)}</div>
          <div style={{
            marginTop: '3px',
            fontSize: '12px',
            color: '#999999',
          }}>{TLT(`税率`)} {toPercent(good?.showCurrency?.valueAddedTaxRate)}</div>
        </div> :
        <div className={styles.price}>
            <div>{good?.showCurrency?.symbol + good?.showCurrency?.salePrice}</div>
            <div style={{
              marginTop: '3px',
              fontSize: '12px',
              color: '#999999',
            }}>{TLT(`未税价`)} {good?.showCurrency?.symbol + good?.showCurrency?.price}</div>
        </div>
      ),
    },
    {
      title: `${TLT(`数量`)}`,
      width: 80,
      render: (_text: unknown, good: Good) => (
        <div style={{ textAlign: 'left', fontSize: '14px',textIndent:'15px' }}>X {good.skuNum}</div>
      ),
    },
    {
      title: (
        <div style={{ width: '100%', textAlign: 'center', paddingLeft: '14px' }}>
          {TLT(`小计`)}&nbsp;
          <Tooltip title={TLT(`如有服务商品，则小计指商品加服务费总价。`)}>
            <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
          </Tooltip>
        </div>
      ),
      width: 128,
      render: (_text: unknown, good: Good) => (
        <div className={styles.total}>
          <span>{TLT(`含税价`) + ' ' + good?.notesCurrencies?.showCurrency?.symbol + (isLocalPro ? formatMoney(good?.notesCurrencies?.showCurrency?.salePrice, false) : good?.notesCurrencies?.showCurrency?.salePrice)}</span>
          <div style={{
            marginTop: '3px',
            fontSize: '12px',
            color: '#999999',
          }}>{TLT(`未税价`)} {good?.notesCurrencies?.showCurrency?.symbol +  (isLocalPro ? formatMoney(good?.notesCurrencies?.showCurrency?.price, false) : good?.notesCurrencies?.showCurrency?.price)}</div>
        </div>
      ),
    },
  ];

  if(isLocalPro){
    columns.splice(3, 0, {
      title: `${TLT(`是否报关`)}`,
      width: 80,
      render: (_text: unknown, good: Good) => (
        <div style={{ textAlign: 'left', fontSize: '14px',paddingLeft:'15px' }}>
          <Checkbox disabled={ userInfo?.companyType == '2' } checked={good.isCustomsDeclaration} onChange={(e)=>{
            const productList = (shipment && shipment.productList) ? [ ...shipment.productList] : [];
            const index = productList.findIndex(item=>item.sku === good.sku);
            const newPRO = {...productList[index]};
            newPRO.isCustomsDeclaration = e.target.checked;
            productList[index] = newPRO;  
            setShipment(Object.assign({},shipment,{
              productList:productList
            }))
        }}/></div>
      )}
    )
  }

  return (
    <>
      {loading && (
        <Table
          className={styles.m}
          columns={columns}
          dataSource={skuList}
          defaultExpandAllRows={true}
          pagination={false}
          rowKey={item => item.mku}
        />
      )}
    </>
  );
};

export default Index;
