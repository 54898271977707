// BatchPurchase.tsx
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Upload, List, message, Modal, Button } from 'antd';
import { UploadOutlined, DownloadOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import styles from './index.module.scss';
import Carts from './components/Carts';
import TextareaWithLineNumbers from './components/TextareaWithLine';
import { getBatchOrderTemplate, batchSmartMatch, uploadBatchTemplate, checkedItemsPrice } from '@/api/newApi/batchOrder';
import { useRecoilState, useSetRecoilState } from './components/Carts/hooks';
import { useTranslation } from 'react-i18next';
import { RecoilRoot } from 'recoil';
import resultImage from '@/assets/images/jdios_images_search_result.png';
import { sum } from 'lodash'
import './ext.scss'

const baseNum = 100;

const BatchPurchase: React.FC = () => {
  const TextareaWithLineNumberRef = useRef();
  const [successProduct, setSuccessProduct] = useRecoilState('successProduct');
  const [errorProduct, setErrorProduct] = useRecoilState('errorProduct');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const [current, setCurrent] = useRecoilState('current');
  const [messageVisible, setMessageVisible] = useState(false);
  const [errorState, setErrorState] = useState(0);
  const [aiNumber, setAiNumber] = useState(0);
  const [selected, setSelected] = useRecoilState('selected');
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const selectedAllInit = (cartWares) => {
    const selectable = cartWares?.filter((item: any) => item.remainNum !== 0)
    console.log(selectable)
    setSelectedSkuList(selectable);
    setSelected(selectable?.map((item: any) => item.sku));
  }
  const errorStateMap = {
    // "2001", "未知错误"
    // "2002", "无效文件"
    // "2003", "文件过大"
    // "2004", "文档需求大于总需求100个限制"
    // "2006", "商品列表不能为空",
    default: {
      title: TLT(`未知错误`),
      content: TLT(`请求处理时出错，请稍后重试或检查输入并重试`),
    },
    2001: {
      title: TLT(`未知错误`),
      content: TLT(`请求处理时出错，请稍后重试或检查输入并重试`),
    },
    2002: {
      title: TLT(`无效文件`),
      content: TLT(`文件内容为空，请填写后重新上传`),
    },
    2006: {
      title: TLT(`无效文件`),
      content: TLT(`文件内容为空，请填写后重新上传`),
    },
    2004: {
      title: TLT(`文档需求大于总需求100个限制`),
      content: TLT(`请调整文档需求数量后重试`),
    },
    2003: {
      title: TLT(`文件过大`),
      content: '',
    },
  };

  const handleCancel = () => {
    setMessageVisible(false);
  };

  const [num, setNum] = useState({
    failedCount: 0,
    successCount: 0,
    reduceCount: baseNum,
  });

  // 1. 文件上传
  // 2. 只能匹配
  const resetValues = (response, selectInit = false) => {
    const { cartResp, failItems, failedCount = 0, successCount = 0, successItems, existsCount = 0, totalCount } = response.value;
    if (successItems) {
      cartResp.cartWares?.forEach(ware => {
        ware._originInput = successItems[ware.sku]
      })
    }
    if (selectInit) {
      // 匹配提示
      Modal.info({
        title: TLT('本次共输入{{tag_0}}条需求，提交成功{{tag_1}}条', { tag_0: totalCount, tag_1: successCount }),
        content: existsCount ? (
          <div>
            <p>{ TLT('{{tag_0}}条需求已存在，无需重复匹配', { tag_0: existsCount} ) }</p>
          </div>
        ) : '',
        okText: TLT(`我知道了`)
      });

      selectedAllInit(cartResp.cartWares)
    }
    setSuccessProduct(cartResp);
    setErrorProduct(failItems);
    setNum({
      failedCount: failedCount,
      successCount: successCount,
      reduceCount: baseNum - failedCount - successCount,
    });
  };

  useEffect(() => {
    const selectedSkus = successProduct.cartWares?.filter(item => selected.includes(item.sku)).map((item) =>({
      sku: item.sku,
      num: item.skuNum,
      price: item.price,
    }))
    checkedItemsPrice(selectedSkus).then((res) => {
      setTotalInfos({
        totalCheckCount: sum(selectedSkus.map(i => i.num)),
        totalCheckKindCount: selected?.length,
        totalPrice: res.value,
      });
    })
  }, [successProduct, selected])

  const beforeUpload = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const currentResult = getCurrentResult();
    formData.append('param', JSON.stringify(currentResult));

    const resData = await uploadBatchTemplate(formData);
    if (resData?.success) {
      resetValues(resData, true);
      return false;
    } else {
      setErrorState(errorStateMap[resData.code] || errorStateMap.default);
      setMessageVisible(true);
    }

    return false;
  };

  const getCurrentResult = (cartWares, errorTextFilter = '') => {
    return {
      successItems:
        cartWares ||
        successProduct?.cartWares?.map(item => ({
          sku: item.sku,
          num: item.skuNum,
        })) ||
        [],
      failItems: errorProduct?.filter(i => i !== errorTextFilter),
    };
  };

  const listData = [
    {
      title: TLT(`填写规则`),
      direction1: TLT(`每行一条需求，最多输入100条`),
      direction2: TLT(`商品编码/物料编码，数量，中间用逗号隔开`),
    },
    {
      title: TLT(`填写正确示例`),
      direction1: `${TLT(`按商品编码填写`)}：100098007070,12`,
      direction2: `${TLT(`按照物料编码填写`)}：NXB-63-29-C16,12`,
    },
  ];

  const downloadTemplate = () => {
    getBatchOrderTemplate().then(res => {
      if (res.success) {
        window.open(res.value.value, '_blank');
      }
    });
  };

  const smartMatch = async (voidText = false, cartWares = null, edit?: object, selectInit = false) => {
    const text = voidText ? '' : TextareaWithLineNumberRef.current.getText();

    if (selectInit && !text) {
      message.error(TLT(`请输入需要匹配的采购内容`))
      return
    }

    let params = {
      input: text,
      ...getCurrentResult(cartWares),
    };
    // TODO
    if (edit) {
      params = {
        input: edit.input,
        ...getCurrentResult(null, edit.originErrorText)
      }
    }

    const res = await batchSmartMatch(params);

    if (res.success) {
      resetValues(res, selectInit);
    } else {
      setErrorState(errorStateMap[res.code] || errorStateMap.default);
      setMessageVisible(true);
    }

    handleClear()
  };

  const updateAfterDelete = useCallback(() => {
    const skuIds = current?.list?.map(item => item.sku);
    const cartWares = successProduct?.cartWares?.filter(item => !skuIds?.includes(item.sku)).map(i => ({
      ...i,
      num: i.skuNum,
    })) || [];

    message.success(TLT(`删除成功`));
    smartMatch(true, cartWares);
    setCurrent(undefined);
  }, [current, successProduct]);

  const updateAdded = ({ skuId, num }) => {
    const cartWares = successProduct?.cartWares?.map(item => {
      return {
        ...item,
        skuNum: skuId === item.sku ? num : item.skuNum,
      };
    });
    setSuccessProduct({
      cartWares
    })
  }

  const deleteHandler = (
    params: {
      type: 'single' | 'batch' | 'available',
      state: 'success' | 'error' | 'normal',
      sku: string | string[]
    }
  ) => {
    const {
      type, state, sku
    } = params
    console.log(params)
    const deleteItems = typeof sku === 'string' ? [sku] : sku

    const data = {
      successItems: successProduct?.cartWares || [],
      failItems: errorProduct,
    }
    if (state === 'success') {
      data.successItems = successProduct.cartWares?.filter(i => !deleteItems.includes(i.sku))
      setSelectedSkuList(selectedSkuList?.filter(i => !deleteItems.includes(i.sku)) || []);
      setSelected(selected.filter((i: any) => !deleteItems.includes(i)));
    }
    if (state === 'error') {
      data.failItems = errorProduct?.filter(i => !deleteItems.includes(i))
    }

    if (type === 'available') {
      data.successItems = successProduct.cartWares?.filter(i => i.remainNum !== 0)
      data.failItems = []
    }

    resetValues({
      value: {
        cartResp: { cartWares: data.successItems },
        failItems: data.failItems,
        failedCount: data.failItems?.length,
        successCount: data.successItems?.length,
      }
    });
  };

  const handleClear = () => {
    if (TextareaWithLineNumberRef.current) {
      TextareaWithLineNumberRef.current.clearText();
    }
  };

  return (
    <div className={styles.bulkPurchaseContainer}>
      {/* Main Title */}
      <h3 className={styles.mainTitle}>{TLT(`批量加购`)}</h3>

      <div className={styles.batchList}>
        {/* 批量上传 */}
        <div className={styles.batchList_file_upload}>
          <div className={styles.batchList_title}>
            <div className={styles.batchList_title_blod}>{TLT(`批量上传`)}</div>
            <div>
              {TLT(`请使用模板填写，需求匹配更准确`)}
              <a onClick={downloadTemplate} style={{ textDecoration: 'underline', marginLeft: 6 }}>
                <DownloadOutlined />
                {TLT(`下载模板`)}
              </a>
            </div>
          </div>

          <div className={`${styles.batchList_main_content} batch-carts-dragger-upload`}>
            <Dragger accept=".xlsx,.xls" maxCount={1} withCredentials={true} beforeUpload={beforeUpload}>
              <Button type="primary" size="large" icon={<UploadOutlined />}>
                {/* 点击或拖拽文件到此处上传 */}
                {TLT(`点击或拖拽文件到此处上传`)}
              </Button>
              <p className={styles.batchList_main_upload_tip}>{TLT(`支持xlsx，最多支持100条`)}</p>
            </Dragger>
          </div>
        </div>

        {/* 智能匹配 */}
        <div className={styles.batchList_file_upload} style={{ paddingRight: 0 }}>
          <div className={styles.batchList_title}>
            <div className={styles.batchList_title_blod}>
              {/* 智能匹配 */}
              {TLT(`智能匹配`)}
            </div>
          </div>

          <div className={styles.batchList_main_content_right}>
            <div className={styles.batchList_main_upload_autp}>
              <TextareaWithLineNumbers ref={TextareaWithLineNumberRef} setAiNumber={setAiNumber} />

              <div className={styles.batchList_main_textarea_controller}>
                <div
                  className={styles.batchList_lower_font}
                  dangerouslySetInnerHTML={{
                    __html: TLT('{{tag_0}}/{{tag_1}}条', {
                      tag_0: `<span className={styles.batchList_main_font}>${aiNumber}</span>`,
                      tag_1: num.reduceCount,
                    }),
                  }}
                ></div>
                <div>
                  <span style={{ marginRight: 12, cursor: 'pointer' }} onClick={() => handleClear()}>
                    {/* 清空 */}
                    {TLT(`清空`)}
                  </span>
                  <Button type="primary" onClick={() => smartMatch(false, null, undefined, true)}>
                    {/* 匹配商品 */}
                    {TLT(`匹配商品`)}
                  </Button>
                </div>
              </div>
            </div>

            <div className={styles.batchList_main_upload_autp_tip}>
              <List
                itemLayout="horizontal"
                dataSource={listData}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={
                        <span style={{ fontSize: 13 }}>
                          {item.direction1}
                          <br />
                          {item.direction2}
                        </span>
                      }
                    />
                  </List.Item>
                )}
              ></List>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.batchList_file_result_tip}>
        <img style={{ width: 30, position: 'relative', top: 5, marginRight: 8 }} src={resultImage} alt="" />
        <span style={{ fontWeight: 500, color: '#333' }}>{TLT(`匹配结果`)}: </span>
        <span
          dangerouslySetInnerHTML={{
            __html: TLT('匹配成功{{tag_0}}个，匹配失败{{tag_1}}个，还可添加{{tag_2}}个', {
              tag_0: `<span>${num.successCount}</span>`,
              tag_1: `<span>${num.failedCount}</span>`,
              tag_2: `<span>${num.reduceCount}</span>`,
            }),
          }}
        ></span>
      </div>

      <Carts
        successProduct={successProduct}
        updateAfterDelete={updateAfterDelete}
        errorProduct={errorProduct}
        updateAdded={updateAdded}
        smartMatch={smartMatch}
        deleteHandler={deleteHandler}
      ></Carts>

      {messageVisible && (
        <Modal
          visible={true}
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={handleCancel}>
              {/* 我知道了 */}
              {TLT(`我知道了`)}
            </Button>,
          ]}
        >
          <div style={{}}>
            <div className={styles.modalMessage}>
              <ExclamationCircleTwoTone twoToneColor="#f81d22" />
              <div className={styles.modalMessageTitle}>{errorState.title}</div>
            </div>
            <div>
              <p>{errorState.content}</p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

function Root() {
  return (
    <RecoilRoot>
      <BatchPurchase />
    </RecoilRoot>
  );
}

export default Root;
