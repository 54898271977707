/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-04-26 19:03:44
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-06-30 20:15:27
 * @FilePath: /isp_International/src/pages/OrderDetailNew/components/LogisticsTrajectory/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { Card ,Empty,Steps } from 'antd';

interface IAppProps {
    LogisticsInfo?: any;
    expectedDeliveryDate?: any;
}
const LogisticsTrajectory: React.FC<IAppProps> = (props: any) => {
    const {LogisticsInfo, expectedDeliveryDate} = props;
    const { t } = useTranslation();

    return <div className={styles.logisticsTrajectory}>
      <div className={styles.main}>
        <div className={styles.box}>
            <span className={styles.title}>{TLT(`物流轨迹`)} <span className={styles.deliveryTime}>{expectedDeliveryDate || ''}</span></span>
            <div className={styles.content}>
                {LogisticsInfo && LogisticsInfo.length  ? <Steps
                    style={{
                        fontSize: '12px',
                    }}
                    progressDot
                    current={0}
                    direction="vertical"
                    items={LogisticsInfo}
                /> : <Empty/>}
            </div>
         </div>
         </div>
    </div>
}

export default LogisticsTrajectory;     