/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-05 14:56:46
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-08-21 10:15:14
 * @FilePath: /isp_International/src/pages/MaterialCode/components/TableItem/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Button, Checkbox, Col, message, Popconfirm, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import { useRecoilState, useSetRecoilState } from '../../hooks';
import { delMaterialCode } from '@/api/newApi/materialcode';
import { useTranslation } from 'react-i18next';
import { addProducts } from '@/api/newApi/search';
import { dispatchShopCarNum } from '@/redux/action';
import { useDispatch } from 'react-redux';


interface TableItemProp{
    data:any;
    checked:boolean;
    onChange:(isChecked:boolean, id:string)=>void;
    onEdit:()=>void;
    onDel:()=>void;
}
const TableItem: React.FC = (props:TableItemProp) => {
    const { data, checked, onChange, onDel, onEdit } = props;
    const { t, i18n } = useTranslation();
    const setShowModal = useSetRecoilState('showModal');
    const [operStatus, setOperStatus] = useRecoilState('operStatus');
    const [selectedId, setSelectedId] = useRecoilState('selectedId');

    const dispatch = useDispatch();

    //加入购物
    const addCarBe = async (num: any, item: any) => {
      if (num === 1) {
        const result: any = await addProducts([
          {
            sku: item.mkuId,
            num: 1,
            price: item?.salePrice,
          },
        ]);
        if (result.success == true) {
          message.success(TLT(`加入采购清单成功`));
          dispatch(dispatchShopCarNum(result.value.totalKindCount));
        } else {
          message.error(result.msg);
        }
      } else {
        message.info(TLT(`该商品不可售`));
      }
    };
    const itemList = [
      {
        span: 4,
        title:TLT(`物料编码`),
        className: [styles.t_cell, styles.textLeft].join(' '),
        key: 'materialId',
        render: function() {
          return (
            <>
              <Checkbox
                className={styles.checkAll}
                style={{ color: '#333' }}
                // indeterminate={indeterminate}
                checked={checked}
                onChange={e => {
                  onChange(e.target.checked, data.id);
                }}
              ></Checkbox>
              <span
                style={{
                  marginLeft: '10px',
                }}
              >
                {data[this.key]}
              </span>
            </>
          );
        },
      },
      {
        span: 5,
        title:TLT(`物料名称`),
        className: [styles.t_cell, styles.textCenter].join(' '),
        key: 'materialName',
      },
      {
        span: 2,
        title:TLT(`京东商品编码`),
        className: [styles.t_cell, styles.textCenter].join(' '),
        key: 'mkuId',
      },
      {
        span: 6,
        title:TLT(`京东商品名称`),
        className: [styles.t_cell, styles.textCenter].join(' '),
        key: 'mkuName',
      },
      {
        span: 3,
        title:TLT(`最后修改人`),
        className: [styles.t_cell, styles.textCenter].join(' '),
        key: 'updater',
        render:()=><div>{data?.userType == 0 ? 'JDi' : data?.updater}</div>
      },
      {
        span: 4,
        title:TLT(`操作`),
        className: [styles.t_cell, styles.textCenter].join(' '),
        key: '',
        render: () => (
          <div className={styles.operation}>
            <Space>
              <Button type="link" onClick={()=>{addCarBe(1, data)}}>{TLT(`加入购物车`)}</Button>
              <Button
                type="link"
                onClick={() => {
                    setOperStatus('edit');
                    setShowModal(true);
                    setSelectedId(data?.id);
                    onEdit && onEdit();
                }}
              >
               {TLT(`编辑`)}
              </Button>
              <Popconfirm
                title="确认删除？"
                onConfirm={async() => {
                    const resData = await delMaterialCode({
                        id:data?.id,
                        clientCode:data?.clientCode
                    });
                    if(resData?.success){
                      onDel && onDel();
                        message.info('删除成功')
                    }else{
                        message.error('失败')
                    }
                }}
                onCancel={() => {
                  console.log(22);
                }}
                okText="是"
                cancelText="否"
              >
                <Button type="link"> {TLT(`删除`)}</Button>
              </Popconfirm>
            </Space>
          </div>
        ),
      },
    ];

    return <Row className={[styles.index, styles.border_top].join(' ')}>
        {itemList.map((ele, idx)=> <Col span={ele.span} className={ele.className} key={idx}>
        {ele.render ? ele.render() : ele.key ? data[ele.key]
         : '-'}
        </Col>)}
    </Row>
}
export default TableItem;

