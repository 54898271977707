import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cookies from 'react-cookies';
import { getClstag } from '@/util/index';
import style from './index.module.scss';
import History from '../history';

import {
  delHistory,
  getSearchHistoryList,
  addSearchHistory,
  getAcquireAssociativeWords,
  isSkuId,
} from '@/api/newApi/search';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
interface IAppProps {
  input_color: string;
  hot_word_id: string;
  hot_color: string;
  search_text: string;
  search_btn_color: string;
  search_size: number;
  search_color: string;
  is_search_hot: boolean;
  bg_color: string;
  is_bg_color: boolean;
  isLenovo: boolean;
  clientOpenWindow: boolean;
  location?: any;
}

const Search: React.FC<IAppProps> = props => {
  const { search_btn_color, isLenovo } = props;
  const [isShow, setIsShow] = useState(false); //是否展示联想词列表
  const [isShowHistory, setIsShowHistory] = useState(false); //是否展示搜索记录列表
  const [sIndex, setSIndex] = useState(-1); //下拉框键盘操作第几个
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputVal, setInputVal] = useState((location as any).query || ''); //文本框输入的值
  const [searchList, setSearchList] = useState([]); //搜索词联想数据
  const [historyList, setHistoryList] = useState([]); // 历史记录数据
  const [isFocus, setIsFocus] = useState(false); //是否获取焦点
  const [islocation, setIsLocation] = useState(cookies.load('stationType')) //本土还是跨境

  // 跳转
  const openWinPage = async (inputVal: any) => {
    setInputVal(inputVal);
    await addSearchHistory(inputVal);
    const result = (await isSkuId(inputVal)) as any;
    if (result.success && result.value) {
      window.location.href = `${window.location.origin}/sku/${result.value}`;
      return;
    }
    const path = {
      pathname: '/page/list',
      query: inputVal,
    };
    history.replace(path);
  };


  //  获取搜索历史记录
  const searchHistoryFn = () => {
    const getData = async () => {
      const result: any = (await getSearchHistoryList()) as any; // [""]
      try {
        if (result?.value?.length) {
          const value = result?.value.filter((i: string) => i);
          setHistoryList(value);
          !inputVal && setIsShowHistory(true); // 展示搜索历史弹框
        }
      } catch (e) {
        setHistoryList(result?.value || []);
        setIsShowHistory(true); // 展示搜索历史弹框
      }
    };
    getData();
  };

  //  删除历史记录
  const delSearchHistoryFn = (num: number, data: object) => {
    const newHistory = [...historyList];
    const getData = async () => {
      const result: any = (await delHistory(data)) as any;
      if (result.success) {
        if (num != -1) {
          newHistory.splice(num, 1);
          setHistoryList(newHistory);
        } else {
          setHistoryList([]);
        }
      }
    };
    getData();
  };

  //搜索联想数据
  const searchOne = (val: string) => {
    val &&
      getAcquireAssociativeWords(val)
        .then((res: any) => {
          if (res.success) {
            setSearchList(res.value.filter((i: string) => i));
            setIsShow(true);
          } else {
            message.error(res.msg);
          }
        })
        .catch(error => {
          message.error(error.msg);
        });
  };
  //搜索框获取焦点的时候增加交互
  const focusWith = () => {
    // const url: any = GetRequest();
    setInputVal(inputVal);
    setSIndex(-1); //清除键盘选中
    //销端改造下掉历史记录
    searchOne(inputVal);
    return;
    if (inputVal !== '') {
      setIsShowHistory(false); // 关闭搜索历史弹框
      setIsShow(true);
    } else {
      setIsShow(false); // 关闭关键词联想弹框
      setIsShowHistory(true);
    }
  
  };
  // 点击账号其他位置收起退出弹框
  const handleHid = (e: any) => {
    //为了解决有时候className获取出一个对象 导致监听事件失效
    if (typeof e?.target?.className !== 'string') {
      return;
    }
    const str = e?.target?.className?.split('_')[1];
    if (str !== 'historyList' && str !== 'searchInput' && str !== 'hotHistoryList') {
      setIsShowHistory(false);
    }
  };
  //搜索框输入change事件
  const changeFn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // const url: any = GetRequest();
     //销端改造下掉历史记录
    if (isLenovo) {
      searchOne(e.target.value);
    }
  
    setInputVal(e.target.value);
    setSIndex(-1); //清除键盘选中

    if (e.target.value !== '') {
      setIsShowHistory(false); // 关闭搜索历史弹框
    } else {
       //销端改造下掉历史记录
      return;
      searchHistoryFn(); //获取下搜索历史的数据
      setIsShow(false); // 关闭关键词联想弹框
      setIsShowHistory(true); // 展示搜索历史弹框
    }

  };

  //获取光标事件
  const focus = () => {
    const dom: any = document.getElementById('G_searchbg');
    if (dom) {
      dom.style.color = 'rgba(0, 0, 0, 0.26)';
    }
    if (inputVal == '') {
      searchHistoryFn();
    }
    focusWith();
    setIsFocus(true);
  };
  //离开光标事件
  const blurs = () => {
    const dom: any = document.getElementById('G_searchbg');
    if (dom) {
      dom.style.color = 'rgba(0, 0, 0, 0.46)';
    }
    // 搜索栏默认搜索直到数组开始切换
    setTimeout(() => {
      setSIndex(-1); //清除键盘选中
      setIsFocus(false);
    }, 300);
    setTimeout(() => {
      setIsShow(false);
    }, 300);
  };

  //搜索联想列表，键盘上下键操控
  const handleKeyUp = (e: any) => {
    if (!isShow) return;
    const keyCode = e.keyCode;
    if (keyCode == 38 || keyCode == 40) {
      const newList: any = searchList.length > 0 && isShow ? [...searchList] : [...historyList];
      //如果没有联想词，禁止键盘上下操作
      if (newList.length <= 0) return;

      let index = sIndex;
      if (keyCode == 38) {
        //方向键向上
        index--;
        if (index < 0) {
          index = newList.length - 1;
        }
      }
      if (keyCode == 40) {
        //方向键向下
        index++;
        if (index > newList.length - 1) {
          index = 0;
        }
      }

      setSIndex(index);
      if (searchList.length > 0 && isShow) {
        setInputVal(newList[index]);
      } else {
        setInputVal(newList[index]);
      }
    }
  };
  // 本场景搜索
  const handleSceneSearch = (inputVal: any) => {
    openWinPage(inputVal);
  };
  //回车事件
  const handleKeyDown = (e: any) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      //回车键
      if (inputVal != '') {
        e.target.blur();
        openWinPage(inputVal);
      }
    }
  };
  //点击搜索按钮
  const handleClick = () => {
     openWinPage(inputVal);
  };
  //点击联想列表
  const lenovoFn = (value: string) => {
    openWinPage(value);
  };
  useEffect(() => {
    document.addEventListener('click', handleHid);
    return () => {
      document.removeEventListener('click', handleHid);
    };
  }, []);
  useEffect(() => {
    if (location.pathname === '/page/list') {
      let searchValue = (location as any).query || '';
      if (!searchValue) {
        searchValue = window.localStorage.getItem('iv') || '';
      }
      if (searchValue && !(location as any).query) {
        openWinPage(searchValue);
      }
      // window.localStorage.setItem('iv', searchValue);
      setInputVal(searchValue);
    } else {
  
      setInputVal('');
    }
  }, [location]);

  return (
    <div className={`${style.seach_con}`}>
      <div
        className={`${style.searchTop} ${isFocus ? style.action : ''}`}
        onMouseLeave={() => {
          // !isFocus && setIsShowHistory(false);
        }}
        onMouseEnter={() => {
          isFocus && !inputVal && setIsShowHistory(true);
        }}
      >
        {isFocus && inputVal != '' ? (
          <i
            className={`${style.searchIconClose}`}
            onClick={() => {
              setInputVal('');
            }}
          ></i>
        ) : (
          ''
        )}
    
        <input
          clstag={getClstag('HomePage_SearchInputBox', 'gc')}
          placeholder=""
          className={`${style.searchInput} `}
          type="text"
          value={inputVal}
          onChange={e => {
            changeFn(e);
          }}
          onFocus={() => {
            //销端改造下掉历史记录联想词
            // focus();
          }}
          onBlur={() => {
            blurs();
          }}
          onKeyUp={e => {
            handleKeyUp(e);
          }}
          onKeyDown={e => {
            handleKeyDown(e);
          }}
        ></input>
        <div
          clstag={getClstag('HomePage_SearchButton', 'gc')}
          className={`${style.searchBtn}`}
          onClick={() => {
            handleClick();
          }}
        >
          <span style={{ color: search_btn_color }}>{TLT(`搜索`)}</span>
        </div>
        {isShowHistory ? (
          <History
            historyList={historyList}
            searchHistoryFnEvent={delSearchHistoryFn}
            downIndex={sIndex}
            handleSceneSearch={handleSceneSearch}
          ></History>
        ) : (
          ''
        )}
        {/* 联想词 */}
        {isShow && inputVal && searchList && searchList?.length ? (
          <ul className={`${style.searchList}`}>
            {searchList.map((item: any, index) => {
              return (
                <li
                  key={index}
                  className={`${sIndex == index ? style.active : ''}`}
                  onClick={() => {
                    lenovoFn(item);
                  }}
                >
                  <div
                    className={`${style.item}`}
                    dangerouslySetInnerHTML={{
                      __html: item.replace(new RegExp(inputVal, 'g'), `<span>${inputVal}</span>`),
                    }}
                  >
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          ''
        )}
      </div>{' '}
    </div>
  );
};
export default Search;
