import React, { useCallback, useEffect,useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import InputNumber from 'rc-input-number';
import { formatMoney, cnyMoney } from '@/util/index';
import { message, Affix } from 'antd';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';

import { useRecoilState, useSetRecoilState } from '../../hooks';
import { updateProductNum, checkProduct, getRemainStock } from '@/api/newApi/carts';
import { getImageDomain } from '@/pages/Carts/utils';
import { formatCartInfo } from '@/util/formatServiceStuff';
import InputNumberPanel from '@/components/InputNumberPanel';
import { toPercent } from '@/util/tools'

import styles from '../../index.module.scss';
import ServiceStuff from '@/components/ServiceStuff';
import EditMaterialCode from '../EditMaterialCode';
let flag: any = null; // inputnumber使用，暂存计时器

const Goods = () => {
  const { t } = useTranslation();
  const setCurrent = useSetRecoilState('current');
  const [skuList, setSkuList] = useRecoilState('skuList');
  const [selected, setSelected] = useRecoilState('selected');
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const [skuTempList, setSkuTempList] = useState([]); //选中的SKU
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0');// 当前站点是否本土 

  //是否是IE白名单
  const clientOpenWindow: any = useSelector(state => {
    return state?.User?.data[0]?.clientOpenWindow;
  });

  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });
  
  const handleNums = useCallback(
    (num, skuId) => {
      if (!num) {
        return;
      }
      log('jdisp', 'Cart_add');
      log('jdisp', 'Cart_remove');
      updateProductNum({
        sku: skuId,
        num,
      }).then(res => {
        if (res.success) {
          setSkuList(res?.value?.cartWares);
          setTotalInfos({
            totalCheckCount: res?.value?.totalCheckCount || 0,
            totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
            totalCount: res?.value?.totalCount || 0,
            totalKindCount: res?.value?.totalKindCount || 0,
            totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
            totalPriceCurrencies:res?.value?.totalPriceCurrencies
          });
          setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item));
        } else {
          message.error(res.msg);
        }
      });
    },
    [skuList],
  );
  /**
   * 获得剩余库存
   * @param number
   * @param sku
   */
  const queryRemainStock = (number:any, sku:any) =>{
    getRemainStock({mkuId:sku, reqNum: number}).then((res:any)=>{
      console.log("res-->",res);
    })
  }

  const handleChangeNums=(item: any, number: any, index: any)=> {
    // 存储计步器数值，用于展示
    const tempSkuList = [...skuList];
    tempSkuList[index] = { ...item, skuNum:number };
    setSkuTempList(tempSkuList);

    // 延迟调用后端接口
    if (flag) {
      clearTimeout(flag);
      flag = null;
    }
    flag = setTimeout(() => {
      handleNums(number,item?.sku);
      // queryRemainStock(number,item?.sku);
    }, 600);
  }
  useEffect(()=>{
    setSkuTempList(skuList);
    console.log('skuList----liyi_skdksld',skuList);
  },[skuList]);

  const handleToggle = useCallback(
    event => {
      event.persist();
      const { currentTarget } = event;
      const { dataset, checked } = currentTarget;
      const skus = dataset.id.split(',');
      const multiple = skus.length > 1;
      const copy = selected.map(item => item);
      if (checked) {
        if (multiple) {
          setSelected(skus);
        } else {
          setSelected(copy.concat(skus));
        }
      } else {
        if (multiple) {
          setSelected([]);
          setSelectedSkuList([]);
        } else {
          copy.splice(selected.indexOf(skus[0]), 1);
          setSelected(copy);
        }
      }
     
      checkProduct({
        skus: skus,
        checked: checked,
      }).then(res => {
        if (res?.success) {
          setTotalInfos({
            totalCheckCount: res?.value?.totalCheckCount || 0,
            totalCheckKindCount: res?.value?.totalCheckKindCount || 0,
            totalCount: res?.value?.totalCount || 0,
            totalKindCount: res?.value?.totalKindCount || 0,
            totalPrice: res?.value?.totalPriceCurrencies?.showCurrency?.salePrice || 0,
            totalPriceCurrencies:res?.value?.totalPriceCurrencies
          });
          setSelectedSkuList(res?.value?.cartWares?.filter((item: any) => item.checked).map((item: any) => item));
        }
      });
    },
    [selected],
  );
  // 获取最小库存
  const getMinStockNum = (item: any,isDisableProduct:boolean) => {
    // console.log("获取最小库存--->",item);
    // 如果商品不可以用
    if(isDisableProduct){
      return 99999;
    } else {
      //有库存
      if(item?.remainNum>0) {
          //选中数量大于库存值
          if(item?.skuNum > item?.remainNum){
            return item?.remainNum || 1;
          }else{
            return item?.lowestBuy || 1;
          }
      }else{
        // 无库存
        return item?.lowestBuy || 1;
      }
    }
    // return isDisableProduct ? 99999 : item?.lowestBuy;
  };
  //获得最大库存
  const getMaxStockNum = (item: any,isDisableProduct:boolean) => {
    // console.log(item?.remainNum);
    if(isDisableProduct){
      return 0;
    }else{
      //有库存
      if(item?.remainNum > 0) {
          return item?.remainNum;
      }else{
        return 99999;
      }
    }
    // return isDisableProduct ? 0 : 99999;
  }
  return (
    <div className={styles.goods}>
      <Affix offsetTop={0}>
        <div className={styles.head}>
          <ul>
            <li>
              {/*<input*/}
              {/*  type="checkbox"*/}
              {/*  checked={selected?.length === skuList?.length}*/}
              {/*  data-id={skuList?.map(item => item.sku)}*/}
              {/*  onChange={handleToggle}*/}
              {/*/>*/}
              {/*<span>{TLT(`全选`)}</span>*/}
            </li>
            <li>{TLT(`商品`)}</li>
            {/* <li>{TLT(`参数`)}</li> */}
            <li>{TLT(`价格`)}</li>
            <li>{TLT(`数量`)}</li>
            <li>{TLT(`小计`)}</li>
            <li>{TLT(`操作`)}</li>
          </ul>
        </div>
      </Affix>
      <div className={styles.content}>
        {skuTempList?.map((item, index) => {
          const gifts = item?.gifts;
          let giftsList = [];
          if (gifts !== undefined) {
            const temp = Object.keys(gifts)?.map(item => ({ code: item, info: gifts[item] }));
            giftsList = formatCartInfo(temp);
          }
          const isDisableProduct = item?.saleState?.saleState === 0;
          return (
            <>
            <div
              className={`${styles.row} ${isDisableProduct ? styles.disableRow : ''} ${
                selected?.indexOf(item.sku) > -1 ? styles.active : ''
              }`}
              key={index}
            >
              <div className={styles.check}>
                {isDisableProduct ? (
                  <i></i>
                ) : (
                  <input
                    type="checkbox"
                    disabled={!item?.inPool || item?.remainNum === 0}
                    checked={selected?.indexOf(item.sku) > -1}
                    data-id={item.sku}
                    onChange={handleToggle}
                  />
                )}
              </div>
              <div className={`${styles.good} ${giftsList?.length > 0 ? styles.mrt10 : ''}`}>
                <div className={styles.main}>
                  <div className={styles.skuImg}>
                    <LazyLoad height={86}>
                      <img src={item.skuImg} alt={item.skuName} />
                    </LazyLoad>
                    {
                      item?.remainNum === 0 && (
                        <div className={styles.stock}>
                          <span>
                            <em style={{ width: '54px' }}>{TLT(`无货`)}</em>
                          </span>
                        </div>
                      )
                    }
                  </div>


                  <div className={styles.detail}>
                    <div className={styles.title}>
                    <a
                        href="#none"
                        onClick={() => {
                          window.open(`/sku/${item.sku}`, clientOpenWindow ? '_self' : '_blank');
                        }}
                        rel="noopener noreferrer"
                        title={item.skuName}
                      >
                        {`${item?.sourceCountryCode == 'CN' ? TLT(`【跨境】`) : ""}${item?.skuName}`}
                      </a>
                    </div>
                    <div className={styles.code}>
                      {TLT(`商品编码`)}：{item.sku}
                    </div>
                    {/* {item.unit && (
                      <div className={styles.unit}>
                        {TLT(`单位：`)}
                        {item.unit}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className={styles.gift}>{giftsList?.length > 0 && <ServiceStuff data={giftsList} />}</div>
                {isDisableProduct && <div className={styles.disableProduct}>{TLT(`该商品不可售`)}</div>}
              </div>
              {/* <div title={item.model} className={styles.specification}>{item.model}</div> */}

               {/* 价格 */} 
              {/* 入池才展示价格 */}
              {/* 本土跨境样式两者是否统一 */}
              {item?.inPool ? 
              (isLocalPro ? 
              <div className={styles.price}>
                  <div>
                    <span >{TLT(`含税价`)} </span> 
                    {(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.salePrice, false)}
                  </div>
                  <div style={{
                    marginTop:"10px",
                    color:"#999999"
                    }
                  }>
                    <span>{TLT(`未税价`)} </span> 
                    {(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.price, false)}
                  </div>
                  <div style={{
                    marginTop:"10px",
                    color:"#999999"
                    }
                  }>
                    <span>{TLT(`税率`)} </span> 
                    {toPercent(item?.showCurrency?.valueAddedTaxRate )}
                  </div>
                </div>: 
                <div className={styles.price}>
                  <div>{(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.salePrice, false)}</div>
                  {item?.showCurrency?.valueAddedTaxRate && <div style={{
                    marginTop:"10px",
                    color:"#999999"
                    }
                  }><span>{TLT(`未税价`)}</span> {(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.price, false)}</div>
                  }
                </div>)
                :
                <div className={styles.price}></div>}

              {/* 数量 */} 
              <div className={(item.moq && item.moq > item.skuNum) ? [styles.amount, styles.redmount].join(' ') : styles.amount}>
                <InputNumberPanel
                  min={getMinStockNum(item,isDisableProduct)}
                  max={getMaxStockNum(item,isDisableProduct)}
                  value={item?.skuNum}
                  stock={item?.remainNum}
                  disabled={isDisableProduct || !item?.inPool || item?.remainNum === 0}
                  changeCallBack={(params: any) => {
                    // 查看更多抽屉 埋点
                    if (!isDisableProduct) {
                      handleChangeNums(item, params, index);
                    }
                  }}
                >
                </InputNumberPanel>

                {/*<InputNumber*/}
                {/*  width={200}*/}
                {/*  min={isDisableProduct ? 99999 : item?.lowestBuy}*/}
                {/*  max={isDisableProduct ? 0 : 99999}*/}
                {/*  defaultValue={item?.skuNum}*/}
                {/*  upHandler={'+'}*/}
                {/*  downHandler={'-'}*/}
                {/*  onChange={value => {*/}
                {/*    if (!isDisableProduct) {*/}
                {/*      handleNums(value, item.sku);*/}
                {/*    }*/}
                {/*  }}*/}
                {/*  disabled={isDisableProduct || !item?.inPool}*/}
                {/*/>*/}
                {/*{item?.lowestBuy > 1 && !isDisableProduct && (*/}
                {/*  <span className={styles.minimum}> {TLT(`最少购买{$}件`).replace('{$}', item?.lowestBuy)}</span>*/}
                {/*)}*/}
                {/*{item?.skuNum >= 99999 && <span className={styles.minimum}>{TLT(`购买最多99999件`)}</span>}*/}



              </div>
              {/* 需跟产品对照是否保持一致 */}
              {/* 小计 */}
              {item?.inPool ?
                isLocalPro ? 
                <div className={styles.total}> 
                  <div>{TLT(`含税价`)} {(item?.notesCurrencies?.showCurrency?.symbol || '') + cnyMoney(item?.notesCurrencies?.showCurrency?.salePrice, false)}</div>
                  <div style={{
                      marginTop:"10px",
                      color:"#E00224"
                      }
                    }>
                      <span>{TLT(`未税价`)}</span> {(item?.notesCurrencies?.showCurrency?.symbol || '') + cnyMoney(item?.notesCurrencies?.showCurrency?.price, false)}
                    </div>
              </div> : 
              <div className={styles.total}> 
                  <div>{(item?.notesCurrencies?.showCurrency?.symbol || '') + cnyMoney(item?.notesCurrencies?.showCurrency?.salePrice, false)}</div>
                  {
                  item?.notesCurrencies?.showCurrency?.valueAddedTaxRate && 
                  <div style={{
                      marginTop:"10px",
                      color:"#E00224"
                      }
                    }>
                      <span>{TLT(`未税价`)}</span> {(item?.notesCurrencies?.showCurrency?.symbol || '') + cnyMoney(item?.notesCurrencies?.showCurrency?.price, false)}
                    </div>
                    }
              </div>: 
              <div className={styles.total}></div>
            }
             <div
                className={styles.operate}
                onClick={() => {
                  setCurrent({
                    method: 'delete',
                    list: [item],
                  });
                }}
              >
                {TLT(`删除`)}
              </div>
            </div>
            {/* 物料编码 */}
            {userLimit?.limitData?.enableMaterial == 1 && <EditMaterialCode 
              key={item?.sku}
              data={item}
              hasMkuMaterial={item?.mkuMaterial}
            />}
            </>
          );
        })}
      
      </div>
    </div>
  );
};

export default Goods;
