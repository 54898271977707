import React, { useCallback, useEffect, useState, useContext } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { formatMoney, cnyMoney } from '@/util/index';
import { message, Affix, Button, Form, Modal, Input, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';

import { useRecoilState, useSetRecoilState } from '../../hooks';
import { checkProduct } from '@/api/newApi/carts';
import { getImageDomain } from '@/pages/Carts/utils';
import { formatCartInfo } from '@/util/formatServiceStuff';
import InputNumberPanel from '@/components/InputNumberPanel';
import { toPercent } from '@/util/tools';
import ParentContext from '@/pages/BatchCarts/context';

import styles from '../../index.module.scss';
import ServiceStuff from '@/components/ServiceStuff';
let flag: any = null; // inputnumber使用，暂存计时器

const Goods = ({ updateAdded }) => {
  const { t } = useTranslation();
  const setCurrent = useSetRecoilState('current');
  const [skuList, setSkuList] = useRecoilState('skuList');
  const [errorSkuProduct, setErrorSkuProduct] = useRecoilState('errorSkuProduct');
  const [selected, setSelected] = useRecoilState('selected');
  const [selectedSkuList, setSelectedSkuList] = useRecoilState('selectedSkuList');
  const setTotalInfos = useSetRecoilState('totalInfos');
  const [skuTempList, setSkuTempList] = useState([]); //选中的SKU
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土
  const [visible, setVisible] = useState(false);
  const [changeInfo, setChangeInfo] = useState([]);
  const { smartMatch, deleteHandler } = useContext(ParentContext);
  const [form] = Form.useForm();
  const { confirm } = Modal;
  //是否是IE白名单
  const clientOpenWindow: any = useSelector(state => {
    return state?.User?.data[0]?.clientOpenWindow;
  });

  const handleNums = useCallback(
    (num, skuId) => {
      if (!num) {
        return;
      }
      updateAdded({
        skuId,
        num,
      });
    },
    [skuList],
  );

  const handleChangeNums = (item: any, number: any, index: any) => {
    // 存储计步器数值，用于展示
    const tempSkuList = [...skuList];
    tempSkuList[index] = { ...item, skuNum: number };
    setSkuTempList(tempSkuList);

    // 延迟调用后端接口
    if (flag) {
      clearTimeout(flag);
      flag = null;
    }
    flag = setTimeout(() => {
      handleNums(number, item?.sku);
    }, 600);
  };
  useEffect(() => {
    setSkuTempList(skuList);
    console.log('skuList----liyi_skdksld', skuList);
  }, [skuList]);

  const handleToggle = useCallback(
    event => {
      console.log('toogle');
      event.persist();
      const { currentTarget } = event;
      const { dataset, checked } = currentTarget;
      const skus = dataset.id.split(',');
      const multiple = skus.length > 1;
      const copy = selected.map(item => item);
      console.log(checked, multiple, '-----');
      if (checked) {
        if (multiple) {
          setSelected(skus);
        } else {
          setSelected(copy.concat(skus));
        }
      } else {
        if (multiple) {
          setSelected([]);
          setSelectedSkuList([]);
        } else {
          copy.splice(selected.indexOf(skus[0]), 1);
          setSelected(copy);
        }
      }
    },
    [selected],
  );
  // 获取最小库存
  const getMinStockNum = (item: any, isDisableProduct: boolean) => {
    // console.log("获取最小库存--->",item);
    // 如果商品不可以用
    if (isDisableProduct) {
      return 99999;
    } else {
      //有库存
      if (item?.remainNum > 0) {
        //选中数量大于库存值
        if (item?.skuNum > item?.remainNum) {
          return item?.remainNum || 1;
        } else {
          return item?.lowestBuy || 1;
        }
      } else {
        // 无库存
        return item?.lowestBuy || 1;
      }
    }
    // return isDisableProduct ? 99999 : item?.lowestBuy;
  };
  //获得最大库存
  const getMaxStockNum = (item: any, isDisableProduct: boolean) => {
    // console.log(item?.remainNum);
    if (isDisableProduct) {
      return 0;
    } else {
      //有库存
      if (item?.remainNum > 0) {
        return item?.remainNum;
      } else {
        return 99999;
      }
    }
    // return isDisableProduct ? 0 : 99999;
  };

  const changeOrder = item => {
    setChangeInfo(item.split(','));
    setVisible(true);
    form.resetFields();
  };

  const deleteErrorItem = callback => {
    confirm({
      title: TLT(`确认删除该需求`),
      content: (
        <div>
          <p>{TLT(`删除后可重新添加`)}</p>
        </div>
      ),
      okText: TLT(`删除`),
      cancelText: TLT(`取消`),
      onOk: callback,
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onSubmit = (values: any, originErrorText) => {
    const formValuesArray = Object.values(values)
      .filter(i => i)
      .map(value => (value === undefined ? '' : value));
    console.log('Form values:', values);
    const info = formValuesArray.join(',');
    console.log(info);
    smartMatch(false, null, {
      input: info,
      originErrorText,
    });
    setVisible(false);
  };

  return (
    <div className={styles.goods}>
      <Affix offsetTop={0}>
        <div className={styles.head}>
          <ul>
            <li>
              {/*<input*/}
              {/*  type="checkbox"*/}
              {/*  checked={selected?.length === skuList?.length}*/}
              {/*  data-id={skuList?.map(item => item.sku)}*/}
              {/*  onChange={handleToggle}*/}
              {/*/>*/}
              {/*<span>{TLT(`全选`)}</span>*/}
            </li>
            <li>{TLT(`原需求`)}</li>
            <li>{TLT(`已匹配商品`)}</li>
            <li>{TLT(`价格`)}</li>
            <li>{TLT(`购买数量`)}</li>
            <li>{TLT(`预估小计`)}</li>
            <li>{TLT(`操作`)}</li>
          </ul>
        </div>
      </Affix>
      <div className={styles.content}>
        {skuTempList?.map((item, index) => {
          const titles = [TLT(`商品编码`), TLT(`物料编码`), TLT(`数量`)];
          const gifts = item?.gifts;
          let giftsList = [];
          if (gifts !== undefined) {
            const temp = Object.keys(gifts)?.map(item => ({ code: item, info: gifts[item] }));
            giftsList = formatCartInfo(temp);
          }
          const isDisableProduct = item?.saleState?.saleState === 0;
          return (
            <div
              className={`${styles.row} ${isDisableProduct ? styles.disableRow : ''} ${
                selected?.indexOf(item.sku) > -1 ? styles.active : ''
              }`}
              key={`${item.sku}-${index}`}
            >
              <div className={styles.check}>
                {isDisableProduct ? (
                  <i></i>
                ) : (
                  <input
                    type="checkbox"
                    disabled={!item?.inPool || item?.remainNum === 0}
                    checked={selected?.indexOf(item.sku) > -1}
                    data-id={item.sku}
                    onChange={handleToggle}
                  />
                )}
              </div>

              {/* 原需求 */}
              <div
                className={styles.originData}
                dangerouslySetInnerHTML={{
                  __html: item._originInput
                    ?.split(',')
                    .map((i, index) => `${titles[index]}: ${i || '-'}`)
                    .join('<br />'),
                }}
              ></div>

              <div className={`${styles.good} ${giftsList?.length > 0 ? styles.mrt10 : ''}`}>
                <div className={styles.main}>
                  <div className={styles.skuImg}>
                    <LazyLoad height={86}>
                      <img src={item.skuImg} alt={item.skuName} />
                    </LazyLoad>
                    {item?.remainNum === 0 && (
                      <div className={styles.stock}>
                        <span>
                          <em style={{ width: '54px' }}>{TLT(`无货`)}</em>
                        </span>
                      </div>
                    )}
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.title}>
                      <a
                        href="#none"
                        onClick={() => {
                          window.open(`/sku/${item.sku}`, clientOpenWindow ? '_self' : '_blank');
                        }}
                        rel="noopener noreferrer"
                        title={item.skuName}
                      >
                        {`${item?.sourceCountryCode == 'CN' ? TLT(`【跨境】`) : ''}${item?.skuName}`}
                      </a>
                    </div>
                    <div className={styles.code}>
                      {TLT(`商品编码`)}：{item.sku}
                    </div>
                    {/* {item.unit && (
                      <div className={styles.unit}>
                        {TLT(`单位：`)}
                        {item.unit}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className={styles.gift}>{giftsList?.length > 0 && <ServiceStuff data={giftsList} />}</div>
                {isDisableProduct && <div className={styles.disableProduct}>{TLT(`该商品不可售`)}</div>}
              </div>
              {/* <div title={item.model} className={styles.specification}>{item.model}</div> */}

              {/* 价格 */}
              {/* 入池才展示价格 */}
              {/* 本土跨境样式两者是否统一 */}
              {item?.inPool ? (
                isLocalPro ? (
                  <div className={styles.price}>
                    <div>
                      <span>{TLT(`含税价`)} </span>
                      {(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.salePrice, false)}
                    </div>
                    <div
                      style={{
                        marginTop: '10px',
                        color: '#999999',
                      }}
                    >
                      <span>{TLT(`未税价`)} </span>
                      {(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.price, false)}
                    </div>
                    <div
                      style={{
                        marginTop: '10px',
                        color: '#999999',
                      }}
                    >
                      <span>{TLT(`税率`)} </span>
                      {toPercent(item?.showCurrency?.valueAddedTaxRate)}
                    </div>
                  </div>
                ) : (
                  <div className={styles.price}>
                    <div>{(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.salePrice, false)}</div>
                    {item?.showCurrency?.valueAddedTaxRate && (
                      <div
                        style={{
                          marginTop: '10px',
                          color: '#999999',
                        }}
                      >
                        <span>{TLT(`未税价`)}</span>{' '}
                        {(item?.showCurrency?.symbol || '') + cnyMoney(item?.showCurrency?.price, false)}
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div className={styles.price}></div>
              )}

              {/* 数量 */}
              <div
                className={
                  item.moq && item.moq > item.skuNum ? [styles.amount, styles.redmount].join(' ') : styles.amount
                }
              >
                <InputNumberPanel
                  min={getMinStockNum(item, isDisableProduct)}
                  max={getMaxStockNum(item, isDisableProduct)}
                  value={item?.skuNum}
                  stock={item?.remainNum}
                  disabled={isDisableProduct || !item?.inPool || item?.remainNum === 0}
                  changeCallBack={(params: any) => {
                    // 查看更多抽屉 埋点
                    if (!isDisableProduct) {
                      handleChangeNums(item, params, index);
                    }
                  }}
                ></InputNumberPanel>
              </div>
              {/* 需跟产品对照是否保持一致 */}
              {/* 小计 */}
              {item?.inPool ? (
                isLocalPro ? (
                  <div className={styles.total}>
                    <div>
                      {TLT(`含税价`)}{' '}
                      {(item?.notesCurrencies?.showCurrency?.symbol || '') +
                        cnyMoney(item?.notesCurrencies?.showCurrency?.salePrice, false)}
                    </div>
                    <div
                      style={{
                        marginTop: '10px',
                        color: '#E00224',
                      }}
                    >
                      <span>{TLT(`未税价`)}</span>{' '}
                      {(item?.notesCurrencies?.showCurrency?.symbol || '') +
                        cnyMoney(item?.notesCurrencies?.showCurrency?.price, false)}
                    </div>
                  </div>
                ) : (
                  <div className={styles.total}>
                    <div>
                      {(item?.notesCurrencies?.showCurrency?.symbol || '') +
                        cnyMoney(item?.notesCurrencies?.showCurrency?.salePrice, false)}
                    </div>
                    {item?.notesCurrencies?.showCurrency?.valueAddedTaxRate && (
                      <div
                        style={{
                          marginTop: '10px',
                          color: '#E00224',
                        }}
                      >
                        <span>{TLT(`未税价`)}</span>{' '}
                        {(item?.notesCurrencies?.showCurrency?.symbol || '') +
                          cnyMoney(item?.notesCurrencies?.showCurrency?.price, false)}
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div className={styles.total}></div>
              )}
              <div className={styles.operate}>
                <a
                  onClick={() =>
                    deleteErrorItem(() => {
                      deleteHandler({
                        type: 'single',
                        state: 'success',
                        sku: item.sku,
                      });
                    })
                  }
                >
                  {TLT(`删除`)}
                </a>
              </div>
            </div>
          );
        })}

        {errorSkuProduct?.map((item, index) => {
          const itmes = item.split(',');
          return (
            <div className={styles.originNees} key={`${item}-${index}`}>
              <div className={styles.originNees_info}>
                { TLT(`商品编码`) }: {itmes[0] || '-'}
                <br />
                {TLT(`物料编码`)}: {itmes[1] || '-'}
                <br />
                {TLT(`数量`)}: {itmes[2]}
              </div>
              <div className={styles.originNees_handler}>
                {TLT(`抱歉，该需求暂时无法匹配`)}
                <Button type="primary" ghost style={{ marginLeft: 30 }} onClick={() => changeOrder(item)}>
                  {TLT(`修改`)}
                </Button>
                <Button
                  danger
                  ghost
                  style={{ marginLeft: 30 }}
                  onClick={() =>
                    deleteErrorItem(() => {
                      deleteHandler({
                        type: 'single',
                        state: 'error',
                        sku: item,
                      });
                    })
                  }
                >
                  {TLT(`删除`)}
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      {visible && (
        <Modal title={TLT(`修改`)} visible={true} onCancel={onCancel} footer={null} centered>
          <p style={{ marginBottom: 10 }}>{TLT(`可修改京东商品编码、物料编码`)}</p>

          <Form form={form} layout="vertical" onFinish={values => onSubmit(values, changeInfo.join(','))}>
            <Form.Item placeholder={TLT(`商品编码`)} name="info1" initialValue={changeInfo[0]}>
              <Input />
            </Form.Item>
            <Form.Item name="info2" initialValue={changeInfo[1]}>
              <Input placeholder={TLT(`物料编码`)} />
            </Form.Item>
            <Form.Item name="info3" initialValue={changeInfo[2]}>
              <Input placeholder={TLT(`数量`)} />
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: 'right' }}>
                <Button onClick={onCancel} style={{ marginRight: 8 }}>
                  {TLT(`取消`)}
                </Button>
                <Button type="primary" onClick={() => form?.submit()}>
                  {/* 提交并重新匹配 */}
                  {TLT(`提交并重新匹配`)}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Goods;
