import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { getUrlParams } from '@/util';
import { CloseOutlined } from '@ant-design/icons';
import PIC_ENMU from '../../enum/index';
import Tips from '@/pages/Login/components/tips';
import Register from '../register/index';
import style from '@/pages/Login/index.module.scss';
import { useTranslation } from 'react-i18next';
import {setVnStyle} from '@/util/init.js'
import dyy from '@/assets/images/login/dyy.png';
import openIcon from '@/assets/images/homeImg/open_icon.png';

const returnUrl = getUrlParams('ReturnUrl', window.location.search);
const quiclframeUrl = `https://m-isp.jd.com/guide`;
const Banner = () => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentLang, setCurrentLang] = useState('zh');
  const [isShow, setIsShow] = useState(false);
  const[isShowSelectModal, setIsShowSelectModal] = useState(false);
  const [isShowQuick, setisShowQuick] = useState(false);
  const [isShowHelp, setisShowHelp] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [quickRegiste, setQuickRegiste] = useState(false);
  const [languageMap] = useState({
    "zh": "中文",
    "en": "English",
    "vi": "Tiếng Việt",
    "hu": "Magyar",
    "pt-BR": "Português Brasileiro",
    "th": "ภาษาไทย",
  });
  const frameUrl = `//passport.jd.com/common/loginPage?from=isp&regTag=2&pwdTag=2&btnTag=992bbfbb9100d5e1&ReturnUrl=${
    returnUrl ? encodeURI(returnUrl) : '//isp.jd.com'
  }`;  
  // const frameUrl = `https://passport.jd.com/common/loginPage?from=jdi_itnational_web&regTag=2&pwdTag=2&btnTag=992bbfbb9100d5e1&ReturnUrl=//buy-pre.jdindustry.com`;  

  
  const { t, i18n } = useTranslation();


  useEffect(() => {
    const handleResize = () => {
      const documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // 假设我们根据窗口宽度来设置 zoomLevel
      // 这里的计算方法仅为示例，你可以根据实际需求来调整
      let zoom = 1
      if(documentWidth < 1200){
        zoom = 1360/1200;
      }
      else if(documentWidth <= 1300 && documentWidth >= 1200){
        zoom = 1360/documentWidth;
      }
      setZoomLevel(zoom);
    };

    // 监听窗口大小变化
    window.addEventListener('resize', handleResize);

    // 初始化尺寸
    handleResize();

    // 清理监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goUpdatePassword = () => {
    window.open('/forget', '_self');
  };
  const bodyScroll = (type = true) => {
    const body = document.querySelector('body');
    if (type) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };

  const showModal = () => {
    bodyScroll(false);
    setIsModalVisible(true);
  };
  const showQuickRegiste = () => {
    bodyScroll(false);
    setQuickRegiste(true);
  };

  const handleOk = () => {
    bodyScroll(true);
    setIsModalVisible(false);
    setQuickRegiste(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setQuickRegiste(false);
    setTimeout(() => {
      bodyScroll(true);
    }, 300);
  };

  useEffect(() => {
    const reqUrl = returnUrl ? encodeURIComponent(returnUrl) : encodeURIComponent('https://' + document.domain);
    document.cookie = `requrl=${reqUrl};path=/`;

    window.addEventListener('message', function(e) {
      if (e.data == 'IS_PC_REGISTERED_CLOSE') {
        bodyScroll(true);
        setIsModalVisible(false);
      }
    });
  }, []);

  useEffect(() => {
    // @ts-ignore
    setCurrentLang(i18n.language)
  }, [])


  //语言列表
  const languageList = Object.entries(languageMap).map(([key, value]) => ({
    label: value,
    value:key
  }));



  console.log(currentLang)

  return (
    <div className={`${style.login_banner}`} style={PIC_ENMU.banner.banner_background}>
      <div className={`${style.login_banner_title}`}>
        <div className={`${style.title_box}`}>
          <div className={`${style.title_box_name}`}>
            {
              TLT(`cn`) === 'cn' ? (
                <img src={PIC_ENMU.banner.banner_big_title} alt="banner_big_title" />
              ) : (
                <img src={PIC_ENMU.banner.banner_big_title_en} alt="banner_big_title_en" />
              )
            }
          </div>
          <div className={`${style.title_box_more}`}>
            
            {/* 选择语言下拉框 */}
            <div className={style.select_box} onClick={()=>setIsShowSelectModal(!isShowSelectModal)}>
              <img src={dyy} className={style.circle} alt="dyy"/>
              {languageMap[currentLang]}
              {isShowSelectModal && <div className={style.select_option}>
                {languageList.map((item, idx)=>item.value != currentLang && <div className={style.select_item} key={idx} onClick={()=>{
                  setCurrentLang(item.value);
                  console.log(item.value)
                  i18n.changeLanguage(item.value);
                  localStorage.setItem('_language', item.value)
                  window.location.reload()
                  item.value == 'vi' && setVnStyle();
                  // window.location.reload();
                }}>{item.label}</div>)}
              </div>}
              <img src={openIcon} className={style.angel} alt="dyy"/>
            </div>
            <div className={`${style.title_box_more_telephone}`}>
              <img src={PIC_ENMU.banner.banner_title_telephone} alt="banner_title_telephone"/>
              400-618-8988
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.login_banner_body}`}>
        <div className={`${style.body_slogan}`}>
          <p className={`${style.body_slogan_title}`}>{TLT(`让工业品国际化采购更简单`)}</p>
          <p className={`${style.body_slogan_subheading}`}>
            {TLT(`为工业企业的出海和国际化布局提供有力的支撑，运用数字化供应链的解决方案，助力客户聚焦主业。`)}
          </p>
        </div>
        <div className={`${style.body_loginbox}`} style={{
          zoom:zoomLevel
        }}>
          <p className={`${style.welText}`}>{TLT(`欢迎使用工采国际版`)}</p>
          <div className={`${style.input}`}>
            <iframe src={frameUrl} id="loginiframe" scrolling="no" width="340" height="245" frameBorder="0"/>
          </div>
          {/* <div className={`${style.tool}`}>
            <div className={`${style.forget}`} onClick={goUpdatePassword}>
              {TLT(`忘记密码？`)}
            </div>
          </div> */}
          <div className={`${style.tips}`}>
            {/*<div className={`${style.tips_tip}`}>*/}
            {/*  <span*/}
            {/*    onClick={() => {*/}
            {/*      showQuickRegiste();*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {TLT(`自助注册开通`)}*/}
            {/*  </span>*/}
            {/*  <img*/}
            {/*    src={!isShowQuick ? PIC_ENMU.banner.banner_q : PIC_ENMU.banner.banner_q_blue}*/}
            {/*    onMouseOver={() => {*/}
            {/*      setisShowQuick(true);*/}
            {/*    }}*/}
            {/*    onMouseOut={() => {*/}
            {/*      setisShowQuick(false);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  {isShowQuick && <Tips text={'无内部审批，采购量适中，期望快速便捷采购的客户。'} />}*/}
            {/*</div>*/}
            {/*<i className={`${style.tips_interval}`}/>*/}
            <div className={`${style.tips_tip}`}>
              <span onClick={showModal}>{TLT(`咨询入口`)}</span>
              {/*<img*/}
              {/*  src={!isShowHelp ? PIC_ENMU.banner.banner_q : PIC_ENMU.banner.banner_q_blue}*/}
              {/*  onMouseOver={() => {*/}
              {/*    setisShowHelp(true);*/}
              {/*  }}*/}
              {/*  onMouseOut={() => {*/}
              {/*    setisShowHelp(false);*/}
              {/*  }}*/}
              {/*/>*/}
              {/*{isShowHelp && <Tips text={'有内部审批，采购量较大，期望搭建专属商城的客户。'} />}*/}
            </div>
          </div>
        </div>
      </div>

      {/*<Modal*/}
      {/*  width={375}*/}
      {/*  title={'快速注册'}*/}
      {/*  visible={quickRegiste}*/}
      {/*  onOk={handleOk}*/}
      {/*  onCancel={handleCancel}*/}
      {/*  footer={null}*/}
      {/*  getContainer={false}*/}
      {/*  bodyStyle={{ padding: `0px`, height: '700px', display: `${quickRegiste ? 'block' : 'none'}` }}*/}
      {/*>*/}
      {/*  <iframe*/}
      {/*    src={`${quiclframeUrl}?time=${new Date().getTime()}`}*/}
      {/*    id="loginiframe"*/}
      {/*    width="375"*/}
      {/*    height="700"*/}
      {/*    frameBorder="0"*/}
      {/*  />*/}
      {/*</Modal>*/}

      <Modal
        width={510}
        visible={isModalVisible}
        onOk={handleOk}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
        onCancel={handleCancel}
        footer={null}
        getContainer={false}
      >
        <Register clickHide={handleCancel} />
      </Modal>
    </div>
  );
};

export default Banner;
